import { template as template_4d353e0abe76497a8684f0c5c7112312 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_4d353e0abe76497a8684f0c5c7112312(`
    <VStack>
      <Header>{{t "idr.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
