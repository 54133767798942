import { template as template_0511bae0683742df997d5b78686a6b4d } from "@ember/template-compiler";
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import { LinkTo } from '@ember/routing';
import { not } from 'tio-ui/utilities';
import Component from '@glimmer/component';
import screenIs from 'tio-common/helpers/screen-is';
import TioFaqs from 'tio-common/components/tio/faqs';
import TioStepOverview from 'tio-common/components/tio/step-overview';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
export interface TuitionAssistanceProgramDetailsOverviewSignature {
    Args: {
        programTemplate: TasProgramTemplateModel;
        isHeldParticipant?: boolean;
        isActiveParticipant?: boolean;
        isRestrictedByProgramExclusivity?: boolean;
        ineligibleBasedOnWaitingPeriod: boolean;
    };
}
export default class TuitionAssistanceProgramDetailsOverview extends Component<TuitionAssistanceProgramDetailsOverviewSignature> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    activeProgramInstancesData = trackedFunction(this, async ()=>{
        const activeInstances = await this.store.query('tas-program-instance', {
            filter: {
                employee: this.sessionContext.currentEmployee.id,
                active: true
            },
            include: 'tas-program-template,tas-applications'
        });
        return activeInstances;
    });
    get activeProgramInstances() {
        return this.activeProgramInstancesData.value || [];
    }
    get isHeldParticipant() {
        return !!this.args.isHeldParticipant;
    }
    get isActiveParticipant() {
        return this.args.isActiveParticipant;
    }
    get isRestrictedByProgramExclusivity() {
        return this.args.isRestrictedByProgramExclusivity;
    }
    get canApplyToProgram() {
        if (this.isHeldParticipant || !this.isActiveParticipant || this.isRestrictedByProgramExclusivity || this.args.ineligibleBasedOnWaitingPeriod || !this.sessionContext.currentEmployee.id || !this.activeProgramInstancesData.value) {
            return false;
        }
        if (this.activeProgramInstances.length === 0 || this.isMultipleInstanceProgram) {
            return true;
        }
        const templateIds = this.activeProgramInstances.map((instance: TasProgramTemplateModel)=>instance.tasProgramTemplate.id);
        const hasActiveInstanceForProgram = templateIds.includes(this.args.programTemplate.id);
        return !hasActiveInstanceForProgram;
    }
    get programSteps() {
        return this.rawProgramSteps.map((step)=>this.intl.t(`tuition_assistance.program_steps.${step}`));
    }
    get instanceForExistingApp() {
        const templateId = this.args.programTemplate.id;
        const instances = this.activeProgramInstances;
        const activeInstanceMatchesTemplateId = instances.find((instance: TasProgramTemplateModel)=>templateId === instance.tasProgramTemplate.id);
        return activeInstanceMatchesTemplateId;
    }
    get programInstance() {
        return this.instanceForExistingApp || {};
    }
    get hideProcessDescriptionSteps() {
        return this.args.programTemplate.hideProcessDescriptionSteps;
    }
    get rawProgramSteps() {
        const model = this.args.programTemplate;
        if (!model) {
            return [];
        }
        if (model.isPreApprovalEnabled && model.isCoursePreApprovalEnabled && model.isReimbursementProgram) {
            return [
                'get_program_approval',
                'get_courses_approval',
                'complete_courses',
                'submit_grades',
                'final_reimbursement'
            ];
        }
        if (model.isPreApprovalEnabled && model.isCoursePreApprovalEnabled && model.isPrepaidProgram) {
            return [
                'get_program_approval',
                'get_courses_approval',
                'prepayment',
                'complete_courses',
                'submit_grades'
            ];
        }
        if (!model.isPreApprovalEnabled && model.isCoursePreApprovalEnabled && model.isReimbursementProgram) {
            return [
                'get_courses_approval',
                'complete_courses',
                'submit_grades',
                'final_reimbursement'
            ];
        }
        if (!model.isPreApprovalEnabled && model.isCoursePreApprovalEnabled && model.isPrepaidProgram) {
            return [
                'get_courses_approval',
                'prepayment',
                'complete_courses',
                'submit_grades'
            ];
        }
        if (!model.isPreApprovalEnabled && !model.isCoursePreApprovalEnabled && model.isReimbursementProgram) {
            return [
                'complete_application',
                'final_reimbursement'
            ];
        }
        // TAS.TODO - Update with missing scenarios
        return [];
    }
    get isMultipleInstanceProgram() {
        return this.args.programTemplate.isMultipleInstanceProgram;
    }
    get buttonText() {
        const templateIds = this.activeProgramInstances.map((instance: TasProgramTemplateModel)=>instance.tasProgramTemplate.id);
        const hasActiveInstanceForProgram = templateIds.includes(this.args.programTemplate.id);
        if (this.isMultipleInstanceProgram && hasActiveInstanceForProgram) {
            return this.intl.t('tuition_assistance.program_details.create_new_program_instance');
        } else {
            return this.intl.t('tuition_assistance.program_details.start_application');
        }
    }
    get isDependentProgram() {
        return this.args.programTemplate.isDependentProgram;
    }
    createInstanceAndApply = dropTask(async ()=>{
        const programTemplate = this.args.programTemplate;
        const instance = this.store.createRecord('tas-program-instance', {
            tasProgramTemplate: programTemplate,
            employee: this.sessionContext.currentEmployee,
            fields: {},
            customFields: programTemplate.instanceCustomFields
        });
        instance.setInitialEmployeeFields();
        await instance.save();
        if (programTemplate.isCertificateProgram) {
            try {
                await this.store.adapterFor('tas-program-instance').requestProgramApproval(instance);
            } catch (e) {
                console.error(e);
            }
            this.router.transitionTo('authenticated.tuition-assistance.programs.instance.certificate.index', instance.id);
        } else {
            this.router.transitionTo('authenticated.tuition-assistance.programs.instance.apply', instance.id);
        }
    });
    static{
        template_0511bae0683742df997d5b78686a6b4d(`
    <div class="flex flex-col">
      <div class="flex w-1/2">
        <TioHeader
          @headerBeginning={{@programTemplate.programName}}
          @description={{@programTemplate.programDescription}}
        />
      </div>
      <div class="flex my-8">
        <Button
          @intent="primary"
          {{on "click" this.createInstanceAndApply.perform}}
          disabled={{not this.canApplyToProgram}}
          @isRunning={{this.createInstanceAndApply.isRunning}}
        >
          {{t "tuition_assistance.program_details.start_application"}}
        </Button>
      </div>
      {{#if this.instanceForExistingApp}}
        <div class="flex text-sm">
          <span class="text-center py-2">
            {{t "tuition_assistance.you_already_have_an_active_program"}}
            {{#if this.isMultipleInstanceProgram}}
              <LinkTo @route="authenticated.tuition-assistance.dashboard" class="tio-copy">
                {{t "click_here"}}
              </LinkTo>
            {{else}}
              <LinkTo
                @route="authenticated.tuition-assistance.programs.instance.index"
                @model={{this.instanceForExistingApp}}
                class="tio-copy"
              >
                {{t "click_here"}}
              </LinkTo>
            {{/if}}
            {{t "tuition_assistance.to_view_program_details"}}
          </span>
        </div>
      {{/if}}
    </div>

    {{#unless this.hideProcessDescriptionSteps}}
      <section class="mt-6 mb-4 md:my-8">
        {{#if (screenIs "md")}}
          <h1 class="mb-3 text-xl font-semibold text-midnight">
            {{t "tuition_assistance.program_details.what_is_the_process"}}
          </h1>
          <TioStepOverview @steps={{this.programSteps}} />
        {{else}}
          <h3 class="text-lg font-semibold text-left">
            {{t "tuition_assistance.program_details.what_is_the_process"}}
          </h3>
          <TioStepOverview @steps={{this.programSteps}} class="pl-4" />
        {{/if}}
      </section>
    {{/unless}}

    <article>
      <h1 class="py-4 font-medium text-xl">
        {{t "tuition_assistance.faq"}}
      </h1>
      {{#each @programTemplate.programFaqs as |faq|}}
        <TioFaqs @question={{faq.heading}} @answer={{faq.body}} class="ml-8" />
      {{/each}}
    </article>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
