import { template as template_5aca9a25174c4d9aaa7c1341cac6b8a8 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
const DashboardWidgetsReimbursementComponent: TOC = template_5aca9a25174c4d9aaa7c1341cac6b8a8(`
  <LinkTo @route="authenticated.tuition-assistance">
    <Tile @headerText={{t "dashboard_tiles.reimbursement"}}>
      <:description>
        {{t "dashboard_tiles.learn_about_reimbursement"}}
      </:description>
      <:image>
        {{svgJar
          "online-library"
          width="75%"
          height="100%"
          role="img"
          desc=(t "svg.online_library")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsReimbursementComponent;
