import { template as template_f245b905127f4619acb23f4e21709ed6 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import DescriptionList from 'tio-ui/components/description-list';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { getServicerName, getBalance, getAccountNumber, getLoans } from 'tio-common/helpers/observability';
// TODO: decide whether footer here should be compositional or a named block - james 2024-12-05
interface FooterSignature {
    Blocks: {
        default: [];
    };
}
const Footer: TOC<FooterSignature> = template_f245b905127f4619acb23f4e21709ed6(`
  <footer class="mt-4 px-4 bg-gray-50 border-t border-gray-200 text-sm py-2">
    {{yield}}
  </footer>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface AccountSummarySignature {
    Args: {
        document: ObservableDocumentModel;
    };
    Blocks: {
        footer?: [];
        default?: [{
                footer: ComponentLike<FooterSignature>;
            }];
    };
    Element: HTMLElement;
}
const AccountSummary: TOC<AccountSummarySignature> = template_f245b905127f4619acb23f4e21709ed6(`
  <article
    class="flex flex-col border border-gray-200 rounded-lg w-full overflow-hidden"
    ...attributes
  >
    <header class="tio-h2 my-4 px-4">
      {{t "observability.document.summary.header"}}
    </header>
    <body class="my-4 px-4">
      <DescriptionList @compact={{true}} as |dl|>
        {{#if (getServicerName @document)}}
          <dl.Group>
            <dl.Term class="font-semibold">{{t "observability.document.summary.servicer"}}</dl.Term>
            <dl.Details>{{getServicerName @document}}</dl.Details>
          </dl.Group>
        {{/if}}
        {{#if (getBalance @document)}}
          <dl.Group>
            <dl.Term class="font-semibold">{{t "observability.document.summary.balance"}}</dl.Term>
            <dl.Details>{{safeFormatNumber
                (getBalance @document)
                style="currency"
                currency="USD"
              }}</dl.Details>
          </dl.Group>
        {{/if}}
        {{#if (getAccountNumber @document)}}
          <dl.Group>
            <dl.Term class="font-semibold">{{t "observability.document.summary.account"}}</dl.Term>
            <dl.Details>{{getAccountNumber @document}}</dl.Details>
          </dl.Group>
        {{/if}}
      </DescriptionList>
      {{#let (getLoans @document) as |loans|}}
        {{#if loans.length}}
          <Table @isLoading={{false}}>
            <:thead as |head|>
              <head.tr>
                <head.th>{{t "observability.document.summary.loans.name"}}</head.th>
                <head.th>{{t "observability.document.summary.loans.balance"}}</head.th>
                <head.th>{{t "observability.document.summary.loans.originated"}}</head.th>
              </head.tr>
            </:thead>
            <:tbody as |body|>
              {{#each loans as |loan|}}
                <body.tr>
                  <body.td>{{loan.name}}</body.td>
                  <body.td>{{safeFormatNumber
                      loan.balance
                      style="currency"
                      currency="USD"
                    }}</body.td>
                  <body.td>{{loan.originated}}</body.td>
                </body.tr>
              {{/each}}
            </:tbody>
          </Table>
        {{/if}}
      {{/let}}
    </body>
    {{#if (has-block "footer")}}
      <footer class="mt-4 px-4 bg-gray-50 border-t border-gray-200 text-sm py-2">
        {{yield to="footer"}}
      </footer>
    {{/if}}
    {{#if (has-block)}}
      {{yield (hash footer=Footer)}}
    {{/if}}
  </article>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AccountSummary;
