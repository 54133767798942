import { template as template_456cdb9d6a5940d7b9dfd862e88fd445 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import type Store from '@ember-data/store';
export interface NonPartnerSignEmbedSignature {
    Args: {
        borrowerCompletedAt: string;
        borrowerName: string;
        approvalId: string;
        documentId: string;
        url: string;
    };
}
export default class NonPartnerSignEmbedComponent extends Component<NonPartnerSignEmbedSignature> {
    @service
    intl: IntlService;
    @service
    session: SessionService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @service
    store: typeof Store;
    get alreadySigned() {
        const documentId = this.args.documentId;
        const status = this.store.adapterFor('pslf-form').pollForEmployerStatus(documentId);
        return status === 'employerSigned';
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
            date: this.args.borrowerCompletedAt,
            name: this.args.borrowerName
        });
    }
    static{
        template_456cdb9d6a5940d7b9dfd862e88fd445(`
    <hr class="my-4" />
    {{#if this.alreadySigned}}
      <div>{{this.employerMessage}}</div>
      <TioButton @outlined={{true}} class="my-4 w-[312px]" color="accent" @linkTo="welcome">
        {{t "continue"}}
        <MaterialIcon @icon="chevron_right" />
      </TioButton>
    {{else}}
      <h3 class="mb-8">
        {{t "pslf.complete_pslf.note"}}
      </h3>
      <iframe class="w-full h-[1000px]" title={{t "pslf.complete_pslf.title"}} src={{@url}} />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
