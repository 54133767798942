import { template as template_99125ad4974147ceb3431982b4c8d9a5 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { AuthenticatedSyfEnrollmentRouteModel } from 'tio-employee/routes/authenticated/syf/enrollment';
import type EmployeeService from 'tio-employee/services/employee';
import SyfEnrollment from 'tio-employee/components/syf/enrollment';
interface SyfEnrollmentRouteSignature {
    Args: {
        model: AuthenticatedSyfEnrollmentRouteModel;
    };
}
class SyfAccountEnrollmentRouteComponent extends Component<SyfEnrollmentRouteSignature> {
    @service
    router: RouterService;
    @service
    employee: EmployeeService;
    @tracked
    beginEnrollmentLoading = false;
    @action
    transitionToNextStep() {
        if (this.args.model.person.activeAccounts.length > 0) {
            this.router.transitionTo('authenticated.syf.account-confirmation');
        } else {
            this.router.transitionTo('authenticated.observability.upload', {
                queryParams: {
                    source: 'syf'
                }
            });
        }
    }
    @action
    async didBeginEnrollment() {
        const matchParticipant = this.args.model.matchParticipant;
        if (!matchParticipant) {
            console.error('Missing valid MatchParticipant for enrollment');
            return;
        }
        if (matchParticipant.isEnrolling) {
            this.transitionToNextStep();
        } else {
            this.beginEnrollmentLoading = true;
            try {
                matchParticipant.person = this.args.model.person;
                await matchParticipant.save();
                await matchParticipant.beginEnrollment();
                this.transitionToNextStep();
            } catch  {
                this.beginEnrollmentLoading = false;
            }
        }
    }
    static{
        template_99125ad4974147ceb3431982b4c8d9a5(`
    <SyfEnrollment
      @onBeginEnrollment={{this.didBeginEnrollment}}
      @beginEnrollmentLoading={{this.beginEnrollmentLoading}}
      @matchPlan={{@model.matchPlan}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfAccountEnrollmentRouteComponent);
