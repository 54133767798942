import { template as template_08a4451d4adc4bf2b642074cf78141db } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { UploadFile } from 'ember-file-upload';
import { service } from '@ember/service';
import { hash } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import type IntlService from 'ember-intl/services/intl';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import Document from 'ember-static-heroicons/components/outline-24/document';
import ExclamationTriangle from 'ember-static-heroicons/components/outline-24/exclamation-triangle';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import { or } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import CardGeneric from 'tio-ui/components/card-generic';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { EXTRACTION_STATES } from 'tio-common/models/observable-document';
import DocumentUploadProgressBar from 'tio-employee/components/observability/upload-progress-bar';
import type RouterService from '@ember/routing/router-service';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
const parseState = (state: keyof typeof EXTRACTION_STATES): string =>{
    return state.substring(state.indexOf('.') + 1);
};
interface UploadProgressSignature {
    Args: {
        upload: UploadFile;
        observableDocument?: ObservableDocumentModel;
        source?: string;
        uploadError?: Error;
        onRetry: () => void;
        timedOut: boolean;
    };
    Element: HTMLDivElement;
}
export default class UploadProgress extends Component<UploadProgressSignature> {
    @service
    router: RouterService;
    @service
    intl: IntlService;
    get documentFailed() {
        const { observableDocument } = this.args;
        const state = observableDocument?.extractionState;
        return state === 'ExtractionState.FAILED';
    }
    get documentInvalid() {
        const { uploadError, observableDocument } = this.args;
        const state = observableDocument?.extractionState;
        return !!uploadError || state === 'ExtractionState.INVALID';
    }
    get transactionHistoryFailedOrInvalid() {
        return [
            [
                'syf_payments'
            ].includes(<string>this.args.source),
            this.documentFailed || this.documentInvalid
        ].every(Boolean);
    }
    get documentSuccessful() {
        return this.args.observableDocument?.extractionState === 'ExtractionState.PROCESSED';
    }
    get processingTimedOut() {
        const { timedOut } = this.args;
        const terminalStates = [
            this.documentSuccessful,
            this.documentInvalid,
            this.documentFailed
        ];
        return timedOut && !terminalStates.some(Boolean);
    }
    get statusTextColor() {
        const { uploadError } = this.args;
        if (uploadError || this.documentFailed || this.documentInvalid) {
            return 'text-red-700';
        } else if (this.documentSuccessful) {
            return 'text-green-700';
        }
        return 'text-indigo-700';
    }
    get progressBarStatus() {
        const { observableDocument, uploadError } = this.args;
        const translation = 'observability.uploads.progress.status';
        if (uploadError) {
            return this.intl.t(translation, {
                state: 'INVALID'
            });
        } else if (observableDocument) {
            const state = parseState(observableDocument.extractionState);
            return this.intl.t(translation, {
                state
            });
        }
        return this.intl.t(translation, {
            state: null
        });
    }
    get progressBarMessage() {
        const { observableDocument, uploadError } = this.args;
        const translation = 'observability.uploads.progress.subtitle';
        if (uploadError || this.documentInvalid) {
            return this.intl.t(translation, {
                state: 'INVALID'
            });
        } else if (observableDocument) {
            const state = parseState(observableDocument.extractionState);
            return this.intl.t(translation, {
                state
            });
        }
        return this.intl.t(translation, {
            state: null
        });
    }
    get manualTransactionUrl() {
        // only SYF for now; generate URLS for other products here if necessary
        return this.router.urlFor('authenticated.syf.payments.new');
    }
    static{
        template_08a4451d4adc4bf2b642074cf78141db(`
    <div class="p-4">
      <div class="flex items-center my-4 text-tio-gray-600">
        <Document class="w-6 mr-2" />
        <span>{{@upload.file.name}}</span>
        {{#if (or this.documentInvalid this.documentFailed)}}
          <ExclamationTriangle class="w-6 ml-2 text-red-700" />
        {{else if this.documentSuccessful}}
          <CheckCircle class="w-6 ml-2 text-green-700" />
        {{/if}}
      </div>
      <DocumentUploadProgressBar
        @observableDocument={{@observableDocument}}
        @upload={{@upload}}
        @uploadError={{@uploadError}}
      />
      <div class="min-h-[4rem] my-4 {{this.statusTextColor}}">
        <h3 class="tio-h4 uppercase">{{this.progressBarStatus}}</h3>
        {{#if this.progressBarMessage}}
          <p>{{this.progressBarMessage}}</p>
        {{/if}}
      </div>
      {{#if this.processingTimedOut}}
        <CardGeneric class="p-4">
          <:header>{{t "observability.uploads.timeout.header"}}</:header>
          <:body>{{t "observability.uploads.timeout.body"}}</:body>
        </CardGeneric>
      {{else if (or this.documentInvalid this.documentFailed)}}
        <Button {{on "click" @onRetry}} @appearance="outlined">
          {{t "observability.uploads.retry"}}
        </Button>
        {{#if this.transactionHistoryFailedOrInvalid}}
          <p class="mt-4"><ActionableTranslation
              @t="observability.confirmation.manual_prompt"
              @links={{hash manualPaymentsLink=(hash t="here" href=this.manualTransactionUrl)}}
            /></p>
        {{/if}}
      {{/if}}
      {{#if this.documentFailed}}
        <p class="my-4">
          {{t "observability.uploads.support.prompt"}}
          <span class="block text-sm my-2">
            {{htmlSafe
              (t
                "observability.uploads.support.email"
                email="support@tuition.io"
                linkClass="tio-link"
              )
            }}
          </span>
          <span class="block text-sm my-2">
            {{t "observability.uploads.support.phone"}}
          </span>
        </p>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
