import { template as template_c62e5ca7e0eb457db4d720929457fb75 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import TioIconButton from 'tio-common/components/tio/icon-button';
import CardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
const employeeFields = [
    'NAME',
    'EMPLOYEE_ID',
    'FT_PT_OTHER',
    'EMPLOYEE_EMAIL'
];
export interface S {
    Args: {
        programInstance: TasProgramInstanceModel;
    };
    Element: HTMLDivElement;
}
const FormsPreApprovalApplicationReviewEmployeeReviewCard: TOC<S> = template_c62e5ca7e0eb457db4d720929457fb75(`
  <CardsGeneric ...attributes>
    <:headerTitle>
      {{t "tuition_assistance.preapproval_app.employee_info"}}
    </:headerTitle>
    <:body>
      <dl class="grid grid-cols-4 gap-2 my-4 w-full">
        <ProgramReadonlyField
          @name="APPLICATION_ID"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          @fields={{@programInstance.fields}}
          as |field|
        >
          <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
          <dd class="col-span-2 font-semibold">
            {{@programInstance.id}}
          </dd>
        </ProgramReadonlyField>
        {{#each employeeFields as |fieldName|}}
          <ProgramReadonlyField
            {{! @glint-expect-error: need better type on field }}
            @name={{fieldName}}
            @programTemplate={{@programInstance.tasProgramTemplate}}
            @fields={{@programInstance.fields}}
            as |field|
          >
            <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
            <dd class="col-span-2 font-semibold break-words">
              {{! @glint-expect-error }}
              {{field.value}}
            </dd>
          </ProgramReadonlyField>
        {{/each}}
      </dl>
    </:body>
    <:footer>
      <div class="flex justify-end">
        <TioIconButton
          @linkTo="authenticated.tuition-assistance.programs.instance.apply.employee-information"
          @linkToModel={{@programInstance.id}}
          @title={{t "common.edit"}}
          @icon="edit"
          @outlined={{true}}
          class="mx-1"
        />
      </div>
    </:footer>
  </CardsGeneric>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FormsPreApprovalApplicationReviewEmployeeReviewCard;
