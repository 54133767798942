import { template as template_d8599e32f5724b33ad69881517b52a34 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array } from '@ember/helper';
import { Modal, Footer } from 'tio-ui/components/modal';
import { object, string } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioFooter from 'tio-common/components/tio/footer';
import TioForm from 'tio-common/components/tio/form';
import TioLoadingMessages from 'tio-common/components/tio/loading-messages';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import LoginHere from 'tio-employee/components/auth/login-here';
class SelfRegistrationRouteComponent extends Component {
    @service
    preRegister: PreRegisterService;
    @service
    router: RouterService;
    @service
    store: typeof Store;
    @tracked
    isSubmitting = false;
    @tracked
    formSubmitted = false;
    formSchema = object({
        work_email: string().required('* Required').email('The Email field must be a valid email'),
        first_name: string().required('* Required'),
        last_name: string().required('* Required')
    });
    @action
    closeDialog() {
        this.router.transitionTo('login');
    }
    @action
    async onSubmit({ work_email, first_name, last_name }: {
        work_email: string;
        first_name: string;
        last_name: string;
    }) {
        this.isSubmitting = true;
        try {
            await this.store.adapterFor('employee').selfRegister({
                email: work_email,
                first_name: first_name,
                last_name: last_name
            });
        } catch (e) {
            console.error(e);
        } finally{
            this.isSubmitting = false;
            this.formSubmitted = true;
        }
    }
    static{
        template_d8599e32f5724b33ad69881517b52a34(`
    {{pageTitle (t "login.register")}}
    <div class="login flex flex-col space-y-10 justify-center items-center mt-20">
      <div class="bg-white w-full max-w-xl shadow-2xl p-5">
        <div class="text-center mb-6 text-lg">
          {{t "login.what_email"}}
          {{t "login.will_send_email"}}
        </div>

        <TioForm
          class="mx-2 mb-10"
          @i18nPath="login"
          @schema={{this.formSchema}}
          @onSubmit={{this.onSubmit}}
          as |form|
        >
          <form.InputField
            @containerClass="mb-4"
            @name="work_email"
            @type="email"
            @hint={{t "login.email_hint_self_registration"}}
            required
          />

          <form.InputField
            @containerClass="mb-4"
            @name="first_name"
            @type="text"
            @autocomplete="given-name"
            required
          />

          <form.InputField
            @containerClass="mb-4"
            @name="last_name"
            @type="text"
            @autocomplete="family-name"
            required
          />

          <form.Errors class="mt-6" />
          <form.Button class="w-36 my-10 mx-auto" @type="submit" @disableWhenInvalid={{true}} />

          {{#if this.isSubmitting}}
            <div class="flex justify-center">
              <TioLoadingMessages
                @i18nPath="login.loading_messages"
                @messageKeys={{array
                  "verify_credentials"
                  "wait_for_system_warmup"
                  "thank_you_for_your_patience"
                  "fetch_company_data"
                }}
                @initialIntervalMs={{0}}
                @messageIntervalMs={{3000}}
              />
            </div>
          {{/if}}
        </TioForm>

        <LoginHere />
      </div>
    </div>
    <TioFooter />

    <Modal
      @isOpen={{this.formSubmitted}}
      @onClose={{this.closeDialog}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <div class="flex justify-center mt-4">
          {{t "login.action_required"}}
        </div>
      </m.Header>
      <m.Body>
        <p>{{t "login.self_registration_success"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.closeDialog}}
          @submitText={{t "common.close"}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SelfRegistrationRouteComponent);
