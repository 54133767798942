import { template as template_380f6734612d45d98a676010744fcab3 } from "@ember/template-compiler";
import type PslfFormModel from 'tio-common/models/pslf-form';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import type { IntlService } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';
interface AdminPslfFormSuccessRouteSignature {
    Args: {
        model: PslfFormModel;
    };
}
class AdminPslfFormSuccessRoute extends Component<AdminPslfFormSuccessRouteSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get borrowerName() {
        return this.args.model.borrowerFields['borrower-name'];
    }
    get dateEmployerSigned() {
        const dateSigned = this.args.model.employerCompletedAt;
        return dateSigned ? dateSigned : new Date();
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
            date: this.intl.formatDate(this.dateEmployerSigned),
            name: this.borrowerName
        });
    }
    static{
        template_380f6734612d45d98a676010744fcab3(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <Section>
        {{#if this.dateEmployerSigned}}
          <h1 class="text-2xl font-semibold my-4"> {{t "pslf.complete_pslf.title_success"}}</h1>
          <p class="text-lg my-4">{{this.employerMessage}}.</p>
          <p class="text-lg">{{t "pslf.complete_pslf.done_employer"}}</p>
        {{/if}}
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AdminPslfFormSuccessRoute);
