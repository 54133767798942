import { template as template_2ecb211ebff84d7da28b6bdec0b80e3d } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type TasApplicationModel from 'tio-common/models/tas-application';
interface S {
    Args: {
        application: TasApplicationModel;
        courseApprovalRequired: boolean;
    };
}
const TasApplicationsCourseSubmittedComponent: TOC<S> = template_2ecb211ebff84d7da28b6bdec0b80e3d(`
  <div class="flex justify-center my-4">
    {{svgJar "mail-sent" width="212px" role="img" desc=(t "svg.mail_sent")}}
  </div>
  <section class="my-10">
    <h1 class="text-4xl mb-6 text-center">
      {{t "tuition_assistance.submitted.application_submitted"}}
    </h1>
    <h3 class="font-semibold mb-4 text-center">
      {{t "tuition_assistance.submitted.what_happens_now"}}
    </h3>

    <div class="mx-8">
      <ul class="list-disc text-sm md:text-base mx-8">
        {{#let "my-1.5" as |listClass|}}
          <li class={{listClass}}>
            {{! NAME }}
            {{t "tuition_assistance.submitted.your_application_name"}}
            <span class="font-semibold">{{@application.displayName}}</span>
          </li>
          <li class={{listClass}}>
            {{t "tuition_assistance.submitted.courses_in_your_app"}}
          </li>
          {{! COURSES }}
          {{#if @application.activeCourses.length}}
            <ul class="list-inside font-semibold list-disc">
              {{#each @application.activeCourses as |course|}}
                <li>
                  {{course.displayName}}
                </li>
              {{/each}}
            </ul>
          {{/if}}

          {{#if @application.scholarships.length}}
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.scholarships_in_your_app"}}
            </li>
            <ul class="list-inside font-semibold list-disc">
              {{#each @application.scholarships as |award|}}
                <li>
                  {{award.scholarshipName}}
                  -
                  {{safeFormatNumber
                    (divide award.scholarshipAmount 100)
                    style="currency"
                    currency="USD"
                  }}</li>
              {{/each}}
            </ul>
          {{/if}}
          {{! SCHOLARSHIPS }}
          {{#if @courseApprovalRequired}}
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.you_can_review_your_application"}}
            </li>
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.your_application_may_have_multiple_reviewers"}}
            </li>
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.your_application_will_be_reviewed"}}
            </li>
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.once_your_application_is_accepted"}}
            </li>
          {{else}}
            <li class={{listClass}}>
              {{t "tuition_assistance.submitted.you_can_now_begin_your_courses"}}
            </li>
          {{/if}}
        {{/let}}
      </ul>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TasApplicationsCourseSubmittedComponent;
