import { template as template_83b2da408ad346cb8d581e0d4a4395ca } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type { NavGroup } from 'tio-common/components/ui/sidebar/nav';
import AppSidebarNavGroupsAdminTuitionAssistanceComponent from './nav-groups/admin/tuition-assistance';
export interface AppSidebarTasApproverNavSignature {
    Args: {
        nav: {
            group: NavGroup;
        };
    };
}
const AppSidebarTasApproverNavComponent: TOC<AppSidebarTasApproverNavSignature> = template_83b2da408ad346cb8d581e0d4a4395ca(`
  {{#let @nav as |nav|}}
    <AppSidebarNavGroupsAdminTuitionAssistanceComponent @nav={{nav}} />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarTasApproverNavComponent;
