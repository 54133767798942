import { template as template_93d77a32485b49c3a407e301378b55d5 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import RoleSelectCard from './role-select-card';
import type RoleViewModel from 'tio-common/models/role-view';
import type SessionContextService from 'tio-employee/services/session-context';
interface AuthLoginRoleSelectSignature {
    Args: {
        onSelect: (role: RoleViewModel) => void;
    };
    Blocks: {
        default: [];
    };
}
export default class AuthLoginRoleSelectComponent extends Component<AuthLoginRoleSelectSignature> {
    @service
    sessionContext: SessionContextService;
    @tracked
    isSubmitting = false;
    get roles() {
        return this.sessionContext.roles.filter((role)=>role.isActive);
    }
    static{
        template_93d77a32485b49c3a407e301378b55d5(`
    <div class="p-10">
      <h1 class="tio-h1 text-center mb-6 mb-md-10">
        {{t "role_select.prompt"}}
      </h1>

      <div class="flex flex-col sm:flex-row items-center justify-center gap-6">
        {{#each this.roles as |role|}}
          <RoleSelectCard @role={{role}} @onClick={{fn @onSelect role}} />
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
