import { template as template_da5ce727db014e81b08f63270fc1bcfb } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface RecommendationsArticleCardActionsSig {
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const RecommendationsArticleCardActions: TOC<RecommendationsArticleCardActionsSig> = template_da5ce727db014e81b08f63270fc1bcfb(`
  <div class="flex flex-row flex-wrap justify-center mt-6 mb-2" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RecommendationsArticleCardActions;
