import { template as template_79b3b2065b264d9a944c2a49db74b56a } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import AppContentCard from 'tio-common/components/app/content-card';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type RecommendationModel from 'tio-common/models/recommendation';
import ForgivenessEstimatorForgivenessEstimatorForm from 'tio-employee/components/forgiveness-estimator/forgiveness-estimator-form';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
interface S {
    Args: {
        model: LoanSummary;
    };
}
class PslfDashboardForgivenessEstimator extends Component<S> {
    @service
    router: RouterService;
    @action
    didSave(recommendation: RecommendationModel) {
        this.router.transitionTo('authenticated.pslf.dashboard.forgiveness-estimator.results', recommendation);
    }
    static{
        template_79b3b2065b264d9a944c2a49db74b56a(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.pslf.dashboard.forgiveness-estimator.index"
        @label="Forgiveness Estimator"
      />
    </TioPageBreadcrumbs>

    <AppContentCard
      @title={{t "pslf_dashboard.actions.forgiveness_estimator"}}
      @titleClass="text-midnight text-base font-semibold mb-2"
    >
      <div class="flex flex-col items-center md:flex-row md:justify-center md:items-start">
        <ForgivenessEstimatorForgivenessEstimatorForm
          @application="PSLF"
          @loanSummary={{@model}}
          @didSave={{this.didSave}}
          class="max-w-[35rem]"
        />
        <div class="md:ml-10">
          <div class="p-6">
            {{svgJar
              "infographic-screen"
              width="100%"
              role="img"
              desc=(t "svg.infographic_screen")
            }}
          </div>
        </div>
      </div>
    </AppContentCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(PslfDashboardForgivenessEstimator);
