import { template as template_5c21edf0c49f4b01b23666c02006a516 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, eq, not, or } from 'tio-ui/utilities';
import { dropTask, all } from 'ember-concurrency';
import { object } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked, cached } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { setSingleValueForTasField, getSingleValueForTasField, buildValidationSchemaForProgramTemplateFields } from 'tio-common/utils/tuition-assistance/fields';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsAcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import TuitionAssistanceFormsCoursesHeldParticipantWarning from 'tio-common/components/tuition-assistance/forms/courses/held-participant-warning';
import TuitionAssistanceFormsUploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import TuitionAssistanceProgramCustomReadonlyField from 'tio-common/components/tuition-assistance/program-custom-readonly-field';
import TuitionAssistanceProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import TuitionAssistanceProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TASAssetModel from 'tio-common/models/tas-asset';
import type TasCourse from 'tio-common/models/tas-course';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
const number = Number;
export interface TuitionAssistanceFormsCertificateSubmitForReviewSignature {
    Args: {
        course: TasCourse;
    };
}
export default class TuitionAssistanceFormsCertificateSubmitForReview extends Component<TuitionAssistanceFormsCertificateSubmitForReviewSignature> {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @tracked
    customFieldsCopy: TasCourse['fields'][] = [];
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    hasSubmitted = false;
    @tracked
    didSubmitSuccessfully = false;
    @tracked
    submitError = null;
    customFieldsProvince = 'COURSE';
    courseFieldsCopy: TasCourse['fields'];
    courseAssetsCopy: TASAssetModel[];
    constructor(owner: Owner, args: TuitionAssistanceFormsCertificateSubmitForReviewSignature['Args']){
        super(owner, args);
        this.courseFieldsCopy = new TrackedObject(this.args.course.fields);
        // We specifically are not updating custom fields against program config
        // at this point (see `copyFieldsAndUpdatePerProgramTemplate` for reference)
        // because these fields are no longer editable at this point.
        // @ts-expect-error: need to consolidtate these type mismatches
        this.customFieldsCopy = this.args.course.customFields?.map((field)=>{
            return {
                ...field
            };
        }) || [];
        this.courseAssetsCopy = [
            ...(this.args.course.tasAssets ?? [])
        ];
    }
    get courseFormFields() {
        return [
            {
                name: 'COURSE_NAME',
                mask: null
            },
            {
                name: 'COURSE_NUMBER',
                mask: null
            },
            {
                name: 'COURSE_DESCRIPTION',
                mask: null
            },
            {
                name: 'COURSE_CREDIT',
                mask: null
            },
            {
                name: 'COURSE_TUITION',
                mask: 'currency'
            },
            {
                name: 'COURSE_BOOKS',
                mask: 'currency'
            },
            {
                name: 'COURSE_LABS',
                mask: 'currency'
            },
            {
                name: 'COURSE_SOFTWARE',
                mask: 'currency'
            },
            {
                name: 'COURSE_FEES',
                mask: 'currency'
            }
        ];
    }
    get customFieldsForProvince() {
        // @ts-expect-error: need to consolidtate these type mismatches
        return this.customFieldsCopy.filter((field)=>field.province === this.customFieldsProvince);
    }
    get programTemplate() {
        return this.args.course?.tasApplication?.tasProgramInstance?.tasProgramTemplate || {};
    }
    get hasSubmittedForPayment() {
        return this.args.course?.tasApplication?.applicationHasSubmittedForEvidenceApproval;
    }
    get showHeldParticipantWarning() {
        return !!this.args.course?.tasApplication?.tasProgramInstance?.employee?.tasParticipant?.isHeld;
    }
    get requireAttachmentUploads() {
        return this.programTemplate.requireAttachmentsForCourseCompletion;
    }
    get attachmentUploadsVisible() {
        return this.programTemplate.attachmentsForCourseCompletionVisible;
    }
    get hasAttachments() {
        return !!this.args.course?.evidenceAssets?.length;
    }
    get formModel() {
        return {
            COURSE_GRADE: getSingleValueForTasField('COURSE_GRADE', this.courseFieldsCopy)
        };
    }
    get formValidationErrors() {
        const schema = this.dynamicValidationSchema || object();
        try {
            schema?.validateSync?.(this.formModel, {
                abortEarly: false
            });
        } catch (err) {
            return err.inner || [];
        }
        return [];
    }
    get isFormValid() {
        if (this.requireAttachmentUploads) {
            return !this.formValidationErrors.length && this.hasAttachments;
        }
        return this.hasAgreedAllConditions && !this.formValidationErrors.length;
    }
    get conditionsForSubmit() {
        return (this.args.course.tasApplication.tasProgramInstance?.tasProgramTemplate?.applicationApproveEvidenceConditions || []);
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    @cached
    get dynamicValidationSchema() {
        const fields = [
            {
                name: 'COURSE_GRADE',
                rules: {
                    type: 'string'
                }
            }
        ];
        // @ts-expect-error: need to consolidtate these type mismatches
        return buildValidationSchemaForProgramTemplateFields(fields, this.programTemplate);
    }
    @action
    updateCourseGrade(value: string) {
        setSingleValueForTasField('COURSE_GRADE', value, this.courseFieldsCopy);
    }
    @action
    didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
        this.acceptedConditions = conditions;
    }
    submitTask = dropTask(async ()=>{
        if (!this.isFormValid || !this.hasAgreedAllConditions) {
            return;
        }
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.saveAgreementsForConditions.perform();
            await this.saveFields.perform();
            await this.store.adapterFor('tas-application').requestCourseEvidence(this.args.course.tasApplication);
            this.didSubmitSuccessfully = true;
        } catch (e) {
            console.error(e);
            this.submitError = e;
        }
    });
    saveFields = dropTask(async ()=>{
        this.args.course.fields = this.courseFieldsCopy;
        await this.args.course.save();
        await this.args.course.tasApplication.reload();
    });
    saveAgreementsForConditions = dropTask(async ()=>{
        const user = this.sessionContext.user;
        const agreements = this.acceptedConditions.map((condition)=>{
            return this.store.createRecord('agreement', {
                user: user,
                condition: condition
            });
        });
        const promises = agreements.map((agreement)=>{
            return agreement.save();
        });
        const saved = await all(promises);
        return saved;
    });
    static{
        template_5c21edf0c49f4b01b23666c02006a516(`
    {{#if @course.tasApplication.canSubmitEvidence}}
      <div class="flex mb-4">
        <MaterialIcon @icon="priority_high" class="text-error-400" />
        <h4 class="text-tio-gray-700 text-lg font-semibold">
          {{t "tuition_assistance.program_details.courses.please_enter_grades_if_applicable"}}
        </h4>
      </div>
    {{/if}}
    <form class="flex flex-wrap">
      <div class="grid md:grid-cols-2 mt-5 md:mt-10 gap-4 w-full md:w-2/5">
        {{#each this.courseFormFields as |formField|}}
          <div class="mx-8 col-span-1 max-w-96">
            <TuitionAssistanceProgramReadonlyField
              {{! @glint-expect-error: fields need better typing and consolidation}}
              @name={{formField.name}}
              @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
              @fields={{@course.fields}}
              as |field|
            >
              <dt>{{field.programLabel}}</dt>
              {{#if (eq formField.mask "currency")}}
                <dd class="font-semibold pl-2 py-3">
                  {{safeFormatNumber
                    (divide (number (or field.value 0)) 100)
                    style="currency"
                    currency="USD"
                  }}
                </dd>
              {{else}}
                <dd class="font-semibold pl-2 py-3">
                  {{! @glint-expect-error: fields need better typing and consolidation}}
                  {{field.value}}
                </dd>
              {{/if}}
            </TuitionAssistanceProgramReadonlyField>
          </div>
        {{/each}}
        {{#each this.customFieldsForProvince as |customField|}}
          {{! @glint-expect-error: fields need better typing and consolidation}}
          <TuitionAssistanceProgramCustomReadonlyField @customField={{customField}} as |field|>
            <div class="mx-8 col-span-1 max-w-96">
              {{! @glint-expect-error: fields need better typing and consolidation}}
              <dt>{{field.programLabel}}</dt>
              <dd class="font-semibold pl-2 py-3">
                {{! @glint-expect-error: fields need better typing and consolidation}}
                {{field.value}}
              </dd>
            </div>
          </TuitionAssistanceProgramCustomReadonlyField>
        {{/each}}
      </div>

      {{#if @course.tasApplication.canSubmitEvidence}}
        {{#unless this.hasSubmittedForPayment}}
          <div class="md:row-span-4 mt-5 md:mt-10 md:w-1/3">
            <TuitionAssistanceProgramFormField
              @name="COURSE_GRADE"
              @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
              as |field|
            >
              {{#if field.isFieldOptionPicklist}}
                <FormSelect
                  data-legacy-input
                  id={{field.inputId}}
                  {{! @glint-expect-error: Frontile may need PR to allow readonly}}
                  @options={{field.fieldOptions}}
                  @selected={{getSingleValueForTasField field.name this.courseFieldsCopy}}
                  @onChange={{this.updateCourseGrade}}
                  @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                  @containerClass="max-w-56"
                  as |option|
                >
                  {{! @glint-expect-error: not sure how to work with this scenario}}
                  {{option}}
                </FormSelect>
              {{else}}
                <FormInput
                  data-legacy-input
                  @value={{getSingleValueForTasField field.name this.courseFieldsCopy}}
                  @onInput={{this.updateCourseGrade}}
                  @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                  @containerClass="w-48"
                />
              {{/if}}
            </TuitionAssistanceProgramFormField>

            {{#if this.attachmentUploadsVisible}}
              <div class="mt-4">
                <TuitionAssistanceFormsUploadAttachments
                  @programTemplate={{@course.tasApplication.tasProgramInstance.tasProgramTemplate}}
                  @classification="COMPLETION_DOCUMENTS"
                  @attachable={{@course}}
                  @attachableType="tasCourse"
                  @uploadRequired={{this.requireAttachmentUploads}}
                  class="!mx-0"
                />
              </div>
            {{/if}}
          </div>
        {{/unless}}
      {{/if}}

      {{#if @course.tasAssets.length}}
        <div class="mt-5 w-full md:w-1/4 md:mt-10 my-4">
          <p class="text-base font-semibold mb-1">
            {{t "prior_attachments"}}
          </p>
          <ul>
            {{#each @course.tasAssets as |asset|}}
              <li class="font-semibold text-ocean-600 mb-2 text-sm">
                <a
                  href={{asset.signedUrl}}
                  target="_blank"
                  rel="noopener noreferrer"
                >{{asset.filename}}</a>
              </li>
            {{else}}
              <li class="text-sm">{{t "common.none"}}</li>
            {{/each}}
          </ul>
        </div>
      {{/if}}
    </form>

    {{#if this.showHeldParticipantWarning}}
      <TuitionAssistanceFormsCoursesHeldParticipantWarning class="my-4 mx-auto max-w-md" />
    {{/if}}

    {{#if (and @course.tasApplication.canSubmitEvidence (not this.hasSubmittedForPayment))}}
      <section class="mt-10">
        {{#if this.conditionsForSubmit.length}}
          <TuitionAssistanceFormsAcceptConditions
            @conditions={{this.conditionsForSubmit}}
            @onChange={{this.didUpdateAcceptedConditions}}
          />
        {{/if}}
        <div class="flex flex-col md:flex-row md:justify-center items-center gap-4">
          <TioErrorMessages
            @showErrors={{this.hasSubmitted}}
            @error={{this.submitError}}
            class="text-center"
          />
          <div>
            <Button
              @intent="primary"
              {{on "click" this.submitTask.perform}}
              @isRunning={{this.submitTask.isRunning}}
              disabled={{not this.isFormValid}}
              class="mx-8 w-full"
            >
              {{t "tuition_assistance.program_details.status.submit_for_review"}}
            </Button>
          </div>
        </div>
      </section>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
