import { template as template_fa472afc3ceb4b4b83ebe14636891fd9 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import QuestionsLoanTypesCard from 'tio-employee/components/questions/loan-types-card';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface CircleSignature {
    Args: {
        label: string;
    };
}
const Circle: TOC<CircleSignature> = template_fa472afc3ceb4b4b83ebe14636891fd9(`
  <div
    class="shrink-0 w-7 h-7 bg-cyan-600 mr-2 text-white rounded-full text-center items-center inline-block"
  >
    {{@label}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(template_fa472afc3ceb4b4b83ebe14636891fd9(`
    <h3 class="text-center font-semibold">
      {{t "questions_you_have.types_of_student_loans.pros.pros_private"}}
    </h3>
    <div class="flex-row mt-4 grid grid-cols-1 justify-center">
      <QuestionsLoanTypesCard
        @src="/assets/images/questions/icons/dollar.svg"
        @label={{t "questions_you_have.types_of_student_loans.pros.pros_private_text"}}
      />
    </div>
    <div class="text-center mt-8">
      <h3 class="font-semibold">{{t
          "questions_you_have.types_of_student_loans.cons.cons_private"
        }}</h3>
      <div class="flex-row mt-4 grid grid-cols-3 justify-center min-h-content">
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/percent.svg"
          @label={{t
            "questions_you_have.types_of_student_loans.cons.generally_higher_interest_rate"
          }}
        />
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/alert.svg"
          @label={{t
            "questions_you_have.types_of_student_loans.cons.dont_have_repayment_plan_flexibility"
          }}
          class="border-x"
        />
        <QuestionsLoanTypesCard
          @src="/assets/images/questions/icons/double-chevrons-pointing-inward.svg"
          @label={{t "questions_you_have.types_of_student_loans.cons.very_few_options"}}
        />
      </div>
    </div>

    <div class="p-4">
      <h1 class="py-4 font-medium text-xl">
        {{t "questions_you_have.how_to_apply.default"}}
      </h1>
      <div class="py-4">
        <h3 class="text-lg font-semibold truncate text-left">
          <Circle @label={{t "number_one"}} />
          {{t "questions_you_have.how_to_apply.decide_if_cosigner.default" htmlSafe=true}}
        </h3>

        <ul class="list-disc ml-12 mb-4">
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.decide_if_cosigner.point_1"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.decide_if_cosigner.point_2"}}
          </li>
        </ul>
      </div>
      <div class="py-4">
        <h3 class="text-lg font-semibold truncate text-left">
          <Circle @label={{t "number_two"}} />
          {{t
            "questions_you_have.how_to_apply.check_application_requirements.default"
            htmlSafe=true
          }}
        </h3>
        <p class="ml-12 mt-4">
          {{t "questions_you_have.how_to_apply.check_requirements_private.header"}}
        </p>
        <ul class="list-disc ml-12 mb-4">
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.check_requirements_private.point_1"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.check_requirements_private.point_2"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.check_requirements_private.point_3"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.check_requirements_private.point_4"}}
          </li>
          <li class="m-4">
            {{t "questions_you_have.how_to_apply.check_requirements_private.point_5"}}
          </li>
        </ul>
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
