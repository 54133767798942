import { template as template_52476aa5ff484e30b989525bfc438542 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { Section, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_52476aa5ff484e30b989525bfc438542(`
    <VStack>
      <Section>
        <:body>
          <AppPageHeroSection class="-mx-4 -mt-4">
            <:title>
              {{t "planning_for_college.five_two_nine.what_is_529_title"}}
            </:title>
            <:body>
              {{t "planning_for_college.five_two_nine.what_is_529_text"}}
            </:body>
          </AppPageHeroSection>
          <div class="flex flex-col justify-center">
            <img
              class="w-full max-w-48 m-6 self-center"
              alt={{t "planning_for_college.five_two_nine.saving_for_college_alt"}}
              src="/assets/images/planning-for-college/logo-sfc.png"
            />
            <div class="w-full max-w-screen-sm self-center text-base text-center text-neutral-500">
              {{t "planning_for_college.five_two_nine.saving_for_college" htmlSafe=true}}
            </div>
          </div>
          <TioPageTabs as |tabs|>
            <tabs.tab
              @route="authenticated.planning-for-college.529-tools.index"
              @label="Benefits"
            />
            <tabs.tab
              @route="authenticated.planning-for-college.529-tools.savings"
              @label="Savings Over Time"
            />
            <tabs.tab @route="authenticated.planning-for-college.529-tools.faqs" @label="FAQs" />
          </TioPageTabs>

          <VStack>
            {{outlet}}

            <div class="text-xs mt-10 text-neutral-400 leading-5">
              <div class="uppercase">
                {{t "planning_for_college.five_two_nine.disclaimer_title"}}
              </div>
              <div>
                {{t "planning_for_college.five_two_nine.disclaimer_text"}}
              </div>
            </div>
          </VStack>
        </:body>
      </Section>
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
