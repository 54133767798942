import { template as template_09a1086bf6394fdc8e29d7d23853c527 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AppHeader from 'tio-employee/components/app/header';
import AppSidebar from 'tio-employee/components/app/sidebar';
import AuthRegisterPostAuthTermsDialog from 'tio-employee/components/auth/register/post-auth-terms-dialog';
import AuthSessionExpiringDialog from 'tio-employee/components/auth/session-expiring-dialog';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import screenIs from 'tio-common/helpers/screen-is';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';
import style from 'ember-style-modifier/modifiers/style';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { LinkTo } from '@ember/routing';
import type PartnerService from 'tio-employee/services/partner';
import type EmployeeService from 'tio-employee/services/employee';
// const userNavs = {
//   ACCOUNT_OWNER: 'owner',
//   EMPLOYEE_ADMIN: 'admin',
//   FAMILY_MEMBER: 'family',
//   PARTICIPANT: 'employee',
//   PSLF_ADMIN: 'pslfAdmin',
//   TAS_APPROVER: 'tasApprover',
//   TIO_ACCOUNT_MANAGER: 'admin',
//   TIO_ADMIN: 'admin',
// };
interface AuthentecatedRouteSignature {
    Args: {
        model: UserModel;
        controller: {
            newSidebar: boolean;
        };
    };
    Blocks: {
        default: [];
    };
}
class AuthenticatedRouteComponent extends Component<AuthentecatedRouteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    partner: PartnerService;
    @service
    employee: EmployeeService;
    @service
    features: FeaturesService;
    @service
    sideDrawer: SideDrawerService;
    @tracked
    isSidebarOpen = true;
    @tracked
    isFinishedRegisterTermsDialog = false;
    constructor(owner: Owner, args: AuthentecatedRouteSignature['Args']){
        super(owner, args);
        this.sideDrawer.isOpen = args.controller.newSidebar;
    }
    get isSidebarOpenHack() {
        // Bridge until old one is gone
        return this.sideDrawer.isOpen;
    }
    get taPartnerPaymentsReviewPageEnabled() {
        return this.features.isEnabled('taPartnerPaymentsReviewPage');
    }
    get isTaOnly() {
        return this.partner.isTaOnly || this.employee.isTaOnlyEmployee;
    }
    get hasTuitionAssistance() {
        return !!(this.partner.hasNewTuitionAssistance || this.employee.hasLegacyTuitionAssistance);
    }
    get isOwner() {
        return !!this.sessionContext.roles.find((e)=>e.relationshipType === 'ACCOUNT_OWNER');
    }
    get isAdmin() {
        return !!this.sessionContext.roles.find((e)=>e.relationshipType === 'TIO_ADMIN');
    }
    get isParticipant() {
        return !!(this.sessionContext.roles.find((e)=>e.relationshipType === 'PARTICIPANT') && !this.isTaOnly);
    }
    get isEmployeeAdmin() {
        return !!this.sessionContext.roles.find((e)=>e.relationshipType === 'EMPLOYEE_ADMIN');
    }
    get isPslfAdmin() {
        return !!this.sessionContext.roles.find((e)=>e.relationshipType === 'PSLF_ADMIN');
    }
    get isPslfEnabled() {
        return !!this.partner.isPslfEnabled;
    }
    get isSyfEnabled() {
        return !!this.partner.isSyfEnabled;
    }
    get idrEnabled() {
        return this.partner.isIdrEnabled;
    }
    get isTasApprover() {
        return !!this.sessionContext.roles.find((e)=>e.relationshipType === 'TAS.Approver.SUPERVISOR');
    }
    get isTaEnabledSettingExists() {
        return this.partner.companySettings.tuitionAssistance && 'isTaEnabled' in this.partner.companySettings.tuitionAssistance ? true : false;
    }
    get isTaSettingEnabled() {
        return (this.partner.companySettings.tuitionAssistance && !!this.partner.companySettings.tuitionAssistance.isTaEnabled);
    }
    get showTuitionAssistance() {
        if (this.isTaEnabledSettingExists) {
            return !!(this.partner.hasNewTuitionAssistance && this.isTaSettingEnabled);
        } else {
            return this.hasTuitionAssistance;
        }
    }
    get showRegisterTermsDialog() {
        // Use a tracked property to allow for faster closing of the dialog
        // (i.e. better UX) before the sessionContext re-calcs `needsToAcceptTerms`
        if (this.isFinishedRegisterTermsDialog) {
            return false;
        }
        return this.sessionContext.needsToAcceptAppTerms;
    }
    @action
    toggleSidebar() {
        this.sideDrawer.toggleDrawer();
    }
    @action
    async didCompleteSsoRegistration() {
        this.isFinishedRegisterTermsDialog = true;
        // Reload the user with the newly created usage agreement and privacy agreement
        await this.store.findRecord('user', this.sessionContext.user.id, {
            include: 'usage-agreement,privacy-agreement,custom-register-agreements',
            reload: true
        });
    }
    static{
        template_09a1086bf6394fdc8e29d7d23853c527(`
    {{#if @controller.newSidebar}}
      <div class="min-h-screen antialiased bg-gray-50 text-black">
        {{! Most examples use display none with a time, Removing pointer events
          allows css to just do its thing.
      }}
        <div
          class="relative z-50 lg:hidden {{unless this.isSidebarOpenHack 'pointer-events-none'}} "
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-900/80 transition-opacity ease-linear duration-300
              {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
              "
          ></div>

          <div class="fixed inset-0 flex">
            <div
              class="relative mr-16 flex w-full max-w-xs flex-1 transition-transform ease-in-out duration-300
                {{if this.isSidebarOpenHack 'translate-x-0' '-translate-x-full'}}
                "
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5 transition-opacity ease-linear duration-300
                  {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
                  "
              >
                <button type="button" class="-m-2.5 p-2.5" {{on "click" this.toggleSidebar}}>
                  <span class="sr-only">{{t "sidebar.close"}}</span>
                  <XMark class="h-6 w-6 text-white" />
                </button>
              </div>

              {{! Sidebar component, swap this element with another sidebar if you like }}
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-midnight px-6 pb-4">
                <NewAppSidebar
                  @isTaOnly={{this.isTaOnly}}
                  @showTuitionAssistance={{this.showTuitionAssistance}}
                  @isParticipant={{this.isParticipant}}
                  @showPslf={{this.isPslfEnabled}}
                  @showSyf={{this.isSyfEnabled}}
                  @showIdr={{this.idrEnabled}}
                  @isPslfAdmin={{this.isPslfAdmin}}
                  @isTasApprover={{this.isTasApprover}}
                  @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
                  @isEmployeeAdmin={{this.isEmployeeAdmin}}
                  @isAdmin={{this.isAdmin}}
                  @isOwner={{this.isOwner}}
                />
              </div>
            </div>
          </div>
        </div>

        {{! Static sidebar for desktop }}
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-56 lg:flex-col">
          <div
            class="flex grow flex-col gap-y-3 overflow-y-auto border-r border-gray-200 bg-midnight px-6 pb-4"
          >
            <NewAppSidebar
              @isTaOnly={{this.isTaOnly}}
              @showTuitionAssistance={{this.showTuitionAssistance}}
              @isParticipant={{this.isParticipant}}
              @showPslf={{this.isPslfEnabled}}
              @showSyf={{this.isSyfEnabled}}
              @showIdr={{this.idrEnabled}}
              @isPslfAdmin={{this.isPslfAdmin}}
              @isTasApprover={{this.isTasApprover}}
              @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
              @isEmployeeAdmin={{this.isEmployeeAdmin}}
              @isAdmin={{this.isAdmin}}
              @isOwner={{this.isOwner}}
            />
          </div>
        </div>

        <div class="lg:pl-56">
          <AppHeader
            class="/*lg:hidden md:block*/ bg-[linear-gradient(to_bottom,white_0%,white_25%,#e0e0e0_100%)]"
          />
          <main class="bg-[#e0e0e0]">
            <div class="px-0">
              {{outlet}}
            </div>
          </main>
        </div>
      </div>
    {{else}}
      <div class="flex antialiased text-black overflow-hidden">
        {{! Padding left on element below must match the configured sidebar width }}
        <div class="flex flex-col grow {{if (screenIs 'md') 'pl-[4.275rem]'}}">
          <AppHeader {{style order="1"}} />
          {{! template-lint-disable no-duplicate-landmark-elements }}
          <main class="flex grow h-full overflow-auto scroll-smooth max-w-7xl" {{style order="2"}}>
            {{outlet}}
          </main>
        </div>
        {{! Render sidebar after header and main content to ensure it is on top of the main content }}
        <AppSidebar />
      </div>
    {{/if}}

    {{! Showing status handled by dialog component itself }}
    <AuthSessionExpiringDialog />

    {{#if this.showRegisterTermsDialog}}
      <AuthRegisterPostAuthTermsDialog
        @didSave={{this.didCompleteSsoRegistration}}
        @primaryEmailAddress={{@model.primaryEmail}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AuthenticatedRouteComponent);
import AcademicCap from 'ember-static-heroicons/components/outline-24/academic-cap';
import Home from 'ember-static-heroicons/components/outline-24/home';
import UserCircle from 'ember-static-heroicons/components/outline-24/user-circle';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import Lifebuoy from 'ember-static-heroicons/components/outline-24/lifebuoy';
import RocketLaunch from 'ember-static-heroicons/components/outline-24/rocket-launch';
import CurrencyDollar from 'ember-static-heroicons/components/outline-24/currency-dollar';
import BookOpen from 'ember-static-heroicons/components/outline-24/book-open';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import ArrowLeftOnRectangle from 'ember-static-heroicons/components/outline-24/arrow-left-on-rectangle';
import { and, or } from 'tio-ui/utilities';
import type FeaturesService from 'tio-common/services/features';
import Newspaper from 'ember-static-heroicons/components/outline-24/newspaper';
import TableCells from 'ember-static-heroicons/components/outline-24/table-cells';
import ArrowUpTray from 'ember-static-heroicons/components/outline-24/arrow-up-tray';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type Owner from '@ember/owner';
/**
 * Represents a group of items within a sidebar that can be toggled to show or hide its children.
 * This component maintains its open/closed state and toggles it upon clicking the group label.
 *
 * @example
 * <SidebarItemGroup @icon="banknotes" @label="Accounting">
 *   <SidebarItem @route="authenticated.accounting.invoices.index" @label="Invoices" />
 * </SidebarItemGroup>
 *
 * @template
 * Consists of a button that toggles the visibility of a nested list containing child components,
 * which are rendered via the `default` block slot.
 *
 * @interface
 * Args:
 *  - icon?: Icons - Optional icon to display alongside the label.
 *  - label: string - The label text for the toggle button.
 * Blocks:
 *  - default: Component[] - The child components to display when the group is expanded.
 */ interface SidebarItemGroupSignature {
    Args: {
        icon?: unknown;
        label: string;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLLIElement;
}
export class SidebarItemGroup extends Component<SidebarItemGroupSignature> {
    @tracked
    isOpen = true;
    @action
    toggleOpen() {
        this.isOpen = !this.isOpen;
    }
    static{
        template_09a1086bf6394fdc8e29d7d23853c527(`
    <li ...attributes>
      <button
        type="button"
        class="hover:bg-gray-50 hover:text-midnight text-white -mx-2 flex items-start w-full text-left rounded-md p-2 gap-x-1 text-sm font-semibold"
        aria-controls="sub-menu-1"
        aria-expanded="false"
        {{on "click" this.toggleOpen}}
      >
        {{#if @icon}}
          {{! @glint-expect-error: need types on heroicons}}
          <@icon
            class="group-[&.active]:font-bold h-5 w-5 shrink-0 text-white hover:text-midnight"
          />
        {{/if}}

        <p>{{@label}}</p>
        <ChevronRight
          class="ml-auto h-5 w-5 shrink-0
            {{if this.isOpen 'rotate-90 text-gray-500' 'text-gray-400'}}"
        />
      </button>
      <ul
        class="{{unless this.isOpen 'hidden'}}
          mt-1 px-2 [&_a]:py-2 [&_a]:pr-2 [&_a]:pl-6 [&_a]:font-normal [&_a]:hover:text-indigo-600]"
      >
        {{yield}}
      </ul>
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
/**
 * Represents a single item within a sidebar, which is a link that can optionally display an icon.
 *
 * @example
 * <SidebarItem @route="authenticated.people.index" @icon="team" @label="People" />
 *
 * @template
 * Renders as a list item containing a link, potentially with an icon, styled according to its
 * active or hover state. The `active` class styling is conditional based on Ember's `LinkTo`
 * component.
 *
 * @interface
 * Args:
 *  - route: string - The route to link to.
 *  - icon?: Icons - Optional icon to display alongside the label.
 *  - label: string - Text label for the link.
 *  - isSubmenu?: boolean - Optional flag to indicate if this item is part of a submenu (affects styling).
 */ interface SidebarItemSignature {
    Args: {
        route: string;
        icon?: unknown;
        label: string;
        isSubmenu?: boolean;
    };
    Element: HTMLAnchorElement;
}
/**
 * Top-level component for rendering a sidebar navigation structure, containing various navigation items,
 * possibly grouped.
 *
 * @template
 * A structured list of `SidebarItem` and `SidebarItemGroup` components representing different navigation
 * paths and functionalities of an application.
 *
 * @example
 * <Sidebar>
 *   <SidebarItem @route="authenticated.dashboard.index" @label="Dashboard" />
 *   <SidebarItemGroup @label="cog" @label="Settings">
 *     <SidebarItem @route="settings.profile" @label="Profile" />
 *   </SidebarItemGroup>
 * </Sidebar>
 */ export const SidebarItem: TOC<SidebarItemSignature> = template_09a1086bf6394fdc8e29d7d23853c527(`
  {{! TODO: Should element type be configurable?}}
  <li>
    <LinkTo
      @route={{@route}}
      class="group -mx-2 flex gap-x-1 rounded-md p-2 text-sm font-semibold text-white hover:bg-gray-50 hover:text-midnight [&.active]:font-bold"
      ...attributes
    >
      {{#if @icon}}
        {{! @glint-expect-error: need types on heroicons}}
        <@icon
          class="group-[&.active]:font-bold h-5 w-5 shrink-0 text-white group-hover:text-midnight"
        />
      {{/if}}
      <p>
        {{@label}}
      </p>
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface NewAppSidebarSignature {
    Args: {
        isTaOnly: boolean;
        showTuitionAssistance: boolean;
        isParticipant: boolean;
        showPslf: boolean;
        showSyf: boolean;
        showIdr: boolean;
        isAdmin: boolean;
        isOwner: boolean;
        isPslfAdmin: boolean;
        isTasApprover: boolean;
        isTasPartnerPaymentsEnabled: boolean;
        isEmployeeAdmin: boolean;
    };
}
// TODO: Rename once old one is gone.
const NewAppSidebar: TOC<NewAppSidebarSignature> = template_09a1086bf6394fdc8e29d7d23853c527(`
  <div class="flex h-16 shrink-0 items-center">
    <span class="font-semibold text-2xl mt-4 mb-2 text-white">
      {{t "sidebar.tio"}}
    </span>
  </div>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <li>
        <ul role="list" class="-mx-2 space-y-1">
          <SidebarItem @route="authenticated.dashboard" @icon={{Home}} @label="Home" />
          {{#if (or @isAdmin @isOwner @isTasApprover)}}
            <SidebarItem
              @route="authenticated.admin.reports"
              @icon={{TableCells}}
              @label={{t "common.reporting.default"}}
              data-test-sidebar-admin-reports
            />
          {{/if}}
          {{#if @isEmployeeAdmin}}
            <SidebarItem
              @route="authenticated.admin.employees"
              @icon={{UserCircle}}
              @label={{t "sidebar.admin_employees"}}
              data-test-sidebar-admin-employees
            />
          {{/if}}
          {{#if (or @isAdmin @isOwner)}}
            <SidebarItem
              @route="authenticated.admin.eligibility-files"
              @icon={{ArrowUpTray}}
              @label={{t "sidebar.eligibility_files"}}
              data-test-sidebar-admin-eligibility-files
            />
          {{/if}}
          {{#if @isPslfAdmin}}
            <SidebarItem
              @route="authenticated.admin.pslf.forms"
              @icon={{Newspaper}}
              @label={{t "sidebar.admin_pslf"}}
              data-test-sidebar-admin-pslf
            />
          {{/if}}
          {{#if @isTasApprover}}
            <SidebarItemGroup @icon={{UserCircle}} @label={{t "sidebar.admin_tuition_assistance"}}>
              {{#if @isTasPartnerPaymentsEnabled}}
                <SidebarItem
                  @route="authenticated.admin.tuition-assistance.payments"
                  @label={{t "sidebar.payments"}}
                  data-test-sidebar-admin-tuition-assistance-payments
                />
              {{/if}}
              <SidebarItem
                @route="authenticated.admin.tuition-assistance.applications.index"
                @label={{t "tuition_assistance.applications.default"}}
                data-test-sidebar-admin-tuition-assistance-applications
              />
              {{! TODO: Eventually this should be a single link that goes to reporting index page with links to each page }}
              <SidebarItem
                @route="authenticated.admin.reporting.index"
                @label={{t "reports.all_reports_link"}}
                data-test-sidebar-admin-tuition-assistance-reports
              />
            </SidebarItemGroup>
          {{/if}}
          {{#if (and @isTaOnly @showTuitionAssistance)}}
            <SidebarItem
              @route="authenticated.tuition-assistance.dashboard"
              @icon={{AcademicCap}}
              @label="Tuition Assistance"
              data-test-sidebar-employee-tuition-assistance-dashboard
            />
          {{/if}}

          {{#if @isParticipant}}
            <SidebarItemGroup @icon={{UserCircle}} @label="Account Activity">
              <SidebarItem
                @route="authenticated.account-activity.accounts"
                @label="Student Loan Summary"
                data-test-sidebar-employee-student-loan-summary
              />
              <SidebarItem
                @route="authenticated.slr.dashboard"
                @label="Employer Contributions"
                data-test-sidebar-employee-employer-contributions
              />
            </SidebarItemGroup>
            {{#if @showPslf}}
              <SidebarItemGroup @icon={{Lifebuoy}} @label="Loan Forgiveness">
                <SidebarItem
                  @route="authenticated.pslf.dashboard"
                  @label="PSLF"
                  data-test-sidebar-pslf
                />
                <SidebarItem
                  @route="authenticated.pslf.dashboard.my-documents"
                  @label="My Forms and Documents"
                  data-test-sidebar-employee-pslf-my-documents
                />
              </SidebarItemGroup>
            {{/if}}
            {{#if @showTuitionAssistance}}
              <SidebarItem
                @route="authenticated.tuition-assistance.dashboard"
                @icon={{AcademicCap}}
                @label="Tuition Assistance"
                data-test-sidebar-employee-primary-tuition-assistance
              />
            {{/if}}
            {{#if @showSyf}}
              <SidebarItem
                @route="authenticated.syf.dashboard"
                @icon={{RocketLaunch}}
                @label="Secure Your Future"
                data-test-sidebar-employee-primary-secure-your-future
              />
            {{/if}}
            {{#if @showIdr}}
              <SidebarItem
                @icon={{CurrencyDollar}}
                @route="authenticated.idr.dashboard"
                @label="Income-Driven Repayment"
                data-test-sidebar-employee-primary-income-driven-repayment
              />
            {{/if}}

            <SidebarItemGroup
              @icon={{CurrencyDollar}}
              @label="Repaying Debt"
              data-test-sidebar-employee-primary-repaying-student-debt
            >
              <SidebarItem
                @route="authenticated.repaying-student-debt.askjeni"
                @label="Student Loan Coaches"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.loan-forgiveness.index"
                @label="Loan Forgiveness"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard"
                @label="Strategy Finder"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.repayment-options"
                @label="Consolidation & Refinancing"
              />
            </SidebarItemGroup>
            <SidebarItemGroup @icon={{BookOpen}} @label="Questions">
              <SidebarItem
                @route="authenticated.questions-you-have.paying-for-college"
                @label="Types of Student Loans"
              />
              <SidebarItem
                @route="authenticated.questions-you-have.repayment-plans"
                @label="Repayment Plans"
              />
              <SidebarItem
                @route="authenticated.questions-you-have.glossary"
                @label="Student Loan Glossary"
              />
            </SidebarItemGroup>
          {{/if}}
        </ul>
      </li>
      {{! Shows pinning to bottom of sidebar }}
      <li class="mt-auto">
        <SidebarItem @route="logout" @icon={{ArrowLeftOnRectangle}} @label="Logout" />
      </li>
    </ul>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
