import { template as template_74799d5935624bd79e0d59d1e4d55e16 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
const dollarize = (cents: number): number =>cents / 100;
const formatTransactionType = (transactionType: string): string =>{
    switch(transactionType){
        case 'TransactionType.PAYMENT':
            return 'account_transaction.transaction_types.payment';
        case 'TransactionType.INTEREST':
            return 'account_transaction.transaction_types.interest';
        default:
            return 'account_transaction.transaction_types.ambiguous';
    }
};
interface TransactionsSummarySignature {
    Args: {
        document: ObservableDocumentModel;
    };
    Element: HTMLElement;
}
const TransactionsSummary: TOC<TransactionsSummarySignature> = template_74799d5935624bd79e0d59d1e4d55e16(`
  <article
    class="flex flex-col border border-gray-200 rounded-lg w-full overflow-hidden"
    ...attributes
  >
    <header class="tio-h2 my-4 px-4">{{t "observability.transactions_summary.header"}}</header>
    <body class="my-4 px-4">
      {{#if @document.accountTransactions.length}}
        <Table>
          <:thead as |head|>
            <head.tr>
              <head.th>{{t "account_transaction.transaction_date"}}</head.th>
              <head.th>{{t "account_transaction.transaction_type"}}</head.th>
              <head.th>{{t "account_transaction.amount"}}</head.th>
            </head.tr>
          </:thead>
          <:tbody as |body|>
            {{#each @document.accountTransactions as |transaction|}}
              <body.tr>
                <body.td>{{transaction.transactionDate}}</body.td>
                <body.td>{{t (formatTransactionType transaction.transactionType)}}</body.td>
                <body.td>{{safeFormatNumber
                    (dollarize transaction.amount)
                    style="currency"
                    currency="USD"
                  }}</body.td>
              </body.tr>
            {{/each}}
          </:tbody>
        </Table>
      {{/if}}
    </body>
  </article>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TransactionsSummary;
