import { template as template_f844e107e1ba4c90b0fe865c8e2d6cc2 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
interface SlrRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SlrRouteSignature>(template_f844e107e1ba4c90b0fe865c8e2d6cc2(`
    <VStack>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
