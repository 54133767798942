import { template as template_87b14573568c4ebbbf085aa48437c9c5 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_87b14573568c4ebbbf085aa48437c9c5(`
    <VStack>{{outlet}}</VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
