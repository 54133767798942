import { template as template_5d7bde5717ae43c2b613a9553005ab3d } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type PlanModel from 'tio-common/models/plan';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface PlanWellnessCardSignature {
    Args: {
        plan: PlanModel;
    };
    Element: HTMLDivElement;
}
const PlanWellnessCardComponent: TOC<PlanWellnessCardSignature> = template_5d7bde5717ae43c2b613a9553005ab3d(`
  <div
    class="text-gray-600 border border-gray-300 rounded p-2 pr-4 m-2 hover:shadow-lg w-3/4 grid grid-cols-2"
    ...attributes
  >
    <div class="col-1">
      <div>
        <span>{{t "plans.plan_key"}}</span>
        <span class="font-semibold">{{@plan.companyKey}}</span>
      </div>
      <div><span>{{t "plans.plan_start_date"}}</span><span class="font-semibold">
          {{safeFormatDate @plan.createdAt}}</span></div>
      <div><span>{{t "plans.plan_name"}}</span><span class="font-semibold">
          {{@plan.name}}</span></div>
      <div><span>{{t "plans.plan_description"}}</span><span class="font-semibold">
          {{@plan.description}}</span></div>
    </div>
    <div class="col-1">
      <div><span>{{t "plans.employees_enrolled"}}</span><span
        >{{@plan.employees_enrolled}}</span></div>
      <div><span>{{t "plans.employee_eligibility_waiting_period"}}</span><span
        >{{@plan.employment_eligibility}}</span></div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PlanWellnessCardComponent;
