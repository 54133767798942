import { template as template_51f83893f763407fa2aa381d1bf5f774 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
export default RouteTemplate(template_51f83893f763407fa2aa381d1bf5f774(`
    <QuestionsLoanForgiveness
      @indexRoute="authenticated.repaying-student-debt.loan-forgiveness.index"
      @incomeRoute="authenticated.repaying-student-debt.loan-forgiveness.income-driven"
      @teacherRoute="authenticated.repaying-student-debt.loan-forgiveness.teacher"
      @stateRoute="authenticated.repaying-student-debt.loan-forgiveness.state-and-local"
    >
      {{outlet}}
    </QuestionsLoanForgiveness>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
