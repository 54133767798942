import { template as template_97fa9d9956c647fdb91a0fda8d4e5487 } from "@ember/template-compiler";
import { eq, or, not, and } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type RouterService from '@ember/routing/router-service';
import XToggle from 'ember-toggle/components/x-toggle';
import AppContentCard from 'tio-common/components/app/content-card';
import type AccountModel from 'tio-common/models/account';
import type AccountTransactionModel from 'tio-common/models/account-transaction';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';
import type MatchPaymentModel from 'tio-common/models/match-payment';
import { Divider } from 'tio-ui/components/utilities';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import SyfDashboardInsights from './dashboard/insights';
import SyfDashboardPersonalEmail from './dashboard/personal-email';
import SyfDashboardMatchSummary from './dashboard/match-summary';
import SyfDashboardLoanPayments from './dashboard/loan-payments';
interface SyfDashboardSignature {
    Args: {
        accounts: AccountModel[];
        certifiedPayments: MatchPaymentModel[];
        qualifiedPayments: MatchPaymentModel[];
        eligiblePayments: AccountTransactionModel[];
        unverifiedPayments: AccountTransactionModel[];
        unqualifiedPayments: MatchPaymentModel[];
        ineligiblePayments: AccountTransactionModel[];
        onCertifyPayments: (payments: Array<MatchPaymentModel>) => Promise<void>;
        getPaymentQualificationState: (txn: AccountTransactionModel) => string;
        getPaymentCertificationState: (txn: AccountTransactionModel) => string;
        matchParticipant: MatchParticipantModel;
        matchPlan: MatchPlanModel;
        focusedAccount: AccountModel | null;
        showAccountVerifyAlert: boolean;
        showAccountUnverifyAlert: boolean;
        onToggleAccountVerified: (account: AccountModel) => void;
        onCancelAccountIsVerified: () => void;
        accountBorrowerConfirmed: boolean;
        onToggleAccountBorrowerConfirmed: () => void;
        onConfirmAccountIsVerified: (account: AccountModel) => void;
    };
}
// This component is temporary and should be replaced with NavTabs when it supports "query" as an arg
// the years are also hard coded so we should discuss making this dynamic
class BenefitYearTabs extends Component {
    @service
    router: RouterService;
    get yearFromQuery() {
        return this.router.currentRoute?.queryParams.benefitYear;
    }
    static{
        template_97fa9d9956c647fdb91a0fda8d4e5487(`
    <nav class="flex space-x-8" area-label="Tabs">
      <LinkTo
        @route="authenticated.syf.dashboard"
        @query={{hash benefitYear="2024"}}
        class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium
          {{if
            (eq this.yearFromQuery '2024')
            'active border-indigo-500 text-indigo-600'
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }}"
      >
        {{! template-lint-disable no-bare-strings }}
        2024
      </LinkTo>

      <LinkTo
        @route="authenticated.syf.dashboard"
        @query={{hash benefitYear="2025"}}
        class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium
          {{if
            (eq this.yearFromQuery '2025')
            'active border-indigo-500 text-indigo-600'
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }}"
      >
        {{! template-lint-disable no-bare-strings }}
        2025
      </LinkTo>
    </nav>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// class method to return translation path given state
// pass to t helper in block component, something like t (getPath payment.transactionState)
const SyfDashboardComponent: TOC<SyfDashboardSignature> = template_97fa9d9956c647fdb91a0fda8d4e5487(`
  <div class="pb-8">
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
    </TioPageBreadcrumbs>
    <BenefitYearTabs />
    <div class="grid grid-cols-6 gap-y-4 md:gap-4">
      <AppContentCard
        class="lg:col-span-4 col-span-6"
        @titleClass="uppercase"
        @title={{t "syf.dashboard.match_and_loan_payment_details"}}
      >
        <SyfDashboardMatchSummary
          @qualifiedPayments={{@qualifiedPayments}}
          @certifiedPayments={{@certifiedPayments}}
          @unqualifiedPayments={{@unqualifiedPayments}}
          @matchPlan={{@matchPlan}}
          @onCertifyPayments={{@onCertifyPayments}}
        />
        <SyfDashboardLoanPayments
          @eligiblePayments={{@eligiblePayments}}
          @ineligiblePayments={{@ineligiblePayments}}
          @unverifiedPayments={{@unverifiedPayments}}
          @getPaymentCertificationState={{@getPaymentCertificationState}}
        />
      </AppContentCard>

      <SyfDashboardInsights
        @matchParticipant={{@matchParticipant}}
        @matchPlan={{@matchPlan}}
        @accounts={{@accounts}}
        class="col-span-6 lg:col-span-2"
      />
      <AppContentCard
        @title={{t "syf.dashboard.loan_accounts.header"}}
        @titleClass="uppercase"
        class="col-span-6 lg:col-span-2 lg:col-start-5"
      >
        <Divider class="my-2" />
        <p class="mb-6">{{t "syf.dashboard.loan_accounts.main_content"}}</p>
        <div class="grid grid-cols-2">
          <div class="col-span-1 font-semibold text-center">
            {{t "syf.dashboard.loan_accounts.account_col_header"}}
          </div>
          <div class="col-span-1 font-semibold text-center">
            {{t "syf.dashboard.loan_accounts.verified_col_header"}}
          </div>
        </div>
        {{#each @accounts as |account|}}
          <div class="grid grid-cols-2">
            <div class="col-span-1">
              {{account.institutionName}}
            </div>
            <div class="col-span-1 text-center">
              <XToggle
                @disabled={{account.isLoading}}
                @label=""
                @theme="material"
                @size="small"
                @value={{account.isVerified}}
                @onToggle={{fn @onToggleAccountVerified account}}
                @showLabels={{true}}
                @offLabel="No"
                @onLabel="Yes"
              />
            </div>
          </div>
          {{! render alert here in order to keep it inside the each loop
          closure so the account var is in scope }}
          <TioConfirmAlert
            @show={{and (eq @focusedAccount.id account.id) @showAccountVerifyAlert}}
            @onConfirm={{fn @onConfirmAccountIsVerified account}}
            @onCancel={{@onCancelAccountIsVerified}}
            @disabled={{or account.isLoading (not @accountBorrowerConfirmed)}}
          >
            <h1 class="text-2xl mb-4">
              {{t "syf.dashboard.loan_accounts.confirmation_alert.verify.header"}}
            </h1>
            <p class="mb-6">
              {{t "syf.dashboard.loan_accounts.confirmation_alert.verify.content"}}
            </p>
            <FormCheckbox
              data-legacy-input
              {{! font-semibold class here doesn't seem to apply to the label
              text in this Frontile component }}
              @containerClass="inline-block m-auto font-semibold"
              @label={{@matchPlan.ensureVerifyAccountStatement}}
              @checked={{@accountBorrowerConfirmed}}
              @onChange={{@onToggleAccountBorrowerConfirmed}}
            />
          </TioConfirmAlert>
          <TioConfirmAlert
            @show={{and (eq @focusedAccount.id account.id) @showAccountUnverifyAlert}}
            @onConfirm={{fn @onConfirmAccountIsVerified account}}
            @onCancel={{@onCancelAccountIsVerified}}
            @disabled={{account.isLoading}}
          >
            <h1 class="text-2xl mb-4">
              {{t "syf.dashboard.loan_accounts.confirmation_alert.unverify.header"}}
            </h1>
            <p class="mb-6">
              {{t "syf.dashboard.loan_accounts.confirmation_alert.unverify.content"}}
            </p>
          </TioConfirmAlert>
        {{/each}}
      </AppContentCard>
      <SyfDashboardPersonalEmail
        @matchParticipant={{@matchParticipant}}
        class="col-span-6 lg:col-span-2 lg:col-start-5"
      />
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SyfDashboardComponent;
