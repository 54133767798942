import { template as template_61e46697ec0047a8bbcfa5f17303abd2 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import TioFaqs from 'tio-common/components/tio/faqs';
import type { MatchPlanFaq } from 'tio-common/models/match-plan';
type SyfFaqsSignature = {
    Args: {
        faqs: MatchPlanFaq[];
    };
};
class SyfFaqsComponent extends Component<SyfFaqsSignature> {
    get orderedFaqs(): Array<MatchPlanFaq> {
        return this.args.faqs.sort((a, b)=>a.ordinal - b.ordinal);
    }
    static{
        template_61e46697ec0047a8bbcfa5f17303abd2(`
    <article class="m-10">
      <h1 class="my-3 text-xl font-semibold">
        {{t "tuition_assistance.faq"}}
      </h1>
      {{#each this.orderedFaqs as |faq|}}
        <TioFaqs @question={{faq.heading}} @answer={{faq.body}} />
      {{/each}}
    </article>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SyfFaqsComponent;
