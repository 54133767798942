import { template as template_d345da0dd38a48e1a171c8cbf9e4fdf4 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RecommendationModel from 'tio-common/models/recommendation';
import type IdrDashboardConrtoller from 'tio-employee/controllers/authenticated/idr/dashboard/recommendations';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import RecommendationsArticleCard from 'tio-employee/components/recommendations/article-card';
import { t } from 'ember-intl';
interface S {
    Args: {
        model: RecommendationModel;
        controller: IdrDashboardConrtoller;
    };
}
class IdrDashboardRecommendationsRoute extends Component<S> {
    get showConsolidationRec() {
        return this.args.controller.recommendation === 'consolidation';
    }
    get showChangeRepaymentPlanRec() {
        return this.args.controller.recommendation === 'changeRepaymentPlan';
    }
    static{
        template_d345da0dd38a48e1a171c8cbf9e4fdf4(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
      <b.crumb @route="authenticated.idr.dashboard.recommendations" @label="Recommendations" />
    </TioPageBreadcrumbs>

    {{#if this.showConsolidationRec}}
      <RecommendationsArticleCard
        @title={{t "recommendations_tab.consolidate_loans"}}
        @subtitle={{t "recommendations_tab.about_consolidation_abridged"}}
        as |article|
      >
        <p class="my-4">
          {{t "recommendations_tab.consolidation_deadline" htmlSafe=true}}
        </p>
        <div class="grid grid-cols-1 xl:grid-cols-4 gap-y-4">
          <article.column @title={{t "recommendations_tab.consolidate_how.header"}}>
            <ol class="list-decimal list-inside">
              <li>
                {{t "recommendations_tab.consolidate_how.point_1" htmlSafe=true}}
              </li>
              <li>{{t "recommendations_tab.consolidate_how.point_2_opt2"}}</li>
              <li>{{t "recommendations_tab.consolidate_how.point_3"}}</li>
              <li>{{t "recommendations_tab.consolidate_how.point_4"}}</li>
            </ol>
          </article.column>
          <article.column @title={{t "recommendations_tab.loan_types_header"}}>
            <ul class="list-disc list-inside">
              <li>
                <span class="font-semibold">
                  {{t "recommendations_tab.ffelp"}}
                </span>
                {{t "recommendations_tab.ffelp_blurb"}}
              </li>
              <li>
                <span class="font-semibold">
                  {{t "recommendations_tab.perkins"}}
                </span>
                {{t "recommendations_tab.perkins_blurb"}}
              </li>
            </ul>
          </article.column>
          <article.column @title={{t "recommendations_tab.still_have_questions"}}>
            <p>{{t "recommendations_tab.schedule_coach" htmlSafe=true}}</p>
          </article.column>
        </div>
        {{! @glint-expect-error: Property actions does not exist on type RecommendationsArticleCardComponent}}
        <article.actions>
          <article.button
            @href="https://studentaid.gov/app/launchConsolidation.action"
            target="blank"
          >
            {{t "recommendations_tab.consolidate_loans"}}
          </article.button>
        </article.actions>
      </RecommendationsArticleCard>
    {{/if}}

    {{#if this.showChangeRepaymentPlanRec}}
      <RecommendationsArticleCard
        @title={{t "recommendations_tab.change_repayment_type.default"}}
        @subtitle={{t "recommendations_tab.change_repayment_type.about_switching"}}
        as |article|
      >
        <div class="grid grid-cols-1 xl:grid-cols-3 gap-y-4">
          <article.column @title={{t "recommendations_tab.change_repayment_type.q_1"}}>
            <p>{{t "recommendations_tab.change_repayment_type.a_1"}}</p>
          </article.column>
          <article.column @title={{t "recommendations_tab.change_repayment_type.q_2"}}>
            <ol class="list-decimal list-inside">
              <li>
                {{t "recommendations_tab.change_repayment_type.q_2_a_1" htmlSafe=true}}
              </li>
              <li>
                {{t "recommendations_tab.change_repayment_type.q_2_a_2"}}
              </li>
              <li>
                {{t "recommendations_tab.change_repayment_type.q_2_a_3" htmlSafe=true}}
              </li>
            </ol>
          </article.column>
          <article.column @title={{t "recommendations_tab.still_have_questions"}}>
            <p>{{t "recommendations_tab.schedule_coach" htmlSafe=true}}</p>
          </article.column>
        </div>
        {{! @glint-expect-error }}
        <article.actions>
          <article.button
            @href="https://studentaid.gov/manage-loans/repayment/plans"
            target="blank"
          >
            {{t "recommendations_tab.change_repayment_type.default"}}
          </article.button>
        </article.actions>
      </RecommendationsArticleCard>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(IdrDashboardRecommendationsRoute);
