import { template as template_cc9892a337a4460f9e020162fa730074 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'tio-ui/utilities';
import { concat } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import DefinitionList from 'tio-common/components/ui/definition-list';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TuitionAssistanceDashboardProgramHeader from './program-header';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type IntlService from 'ember-intl/services/intl';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import UiPill from 'tio-common/components/ui/pill';
import type PartnerService from 'tio-employee/services/partner';
export interface TuitionAssistanceMyProgramsSignature {
    Args: {
        programInstances?: TasProgramInstanceModel[];
        sectionHeader?: string;
        programStatus?: string;
    };
    Element: HTMLDivElement;
}
export type ProgramOverviewData = {
    title: string;
    id: string;
    isTrial: boolean;
    statusText: string;
    date: string;
    buttonRoute: string;
    buttonRouteModel: string;
    buttonText: string;
    institution: {
        [key: string]: string;
    };
    beginDate: string;
    beginDateVisible: boolean;
    endDate: string;
    endDateVisible: boolean;
    result: CourseApplicationResults;
    state: string;
    isTrackingOnlyProgram: boolean;
    programMajor: {
        [key: string]: string;
    };
    isTypeTwoOrThree: boolean;
};
export type CourseApplicationResults = {
    pending: number;
    in_progress: number;
    completed: number;
};
export default class TuitionAssistanceMyPrograms extends Component<TuitionAssistanceMyProgramsSignature> {
    @service
    intl: IntlService;
    @service
    partner: PartnerService;
    get programInstances() {
        return this.args.programInstances || [];
    }
    get newTasUxEnabled() {
        return !!this.partner.companySettings?.tuitionAssistance?.newTasUxEnabled;
    }
    setButtonRoute(typeClassification: string, instance: TasProgramInstanceModel) {
        // TAS.TODO: when working on type 2/4, we will need to also do a check for the companySettings newTasUxEnabled
        // to determine which companies should route to the new or old UX pages
        if (!this.newTasUxEnabled) {
            return {
                route: 'authenticated.tuition-assistance.programs.instance',
                model: instance.id
            };
        }
        switch(typeClassification){
            case 'TAS.ProgramType.2':
            case 'TAS.ProgramType.3':
                return {
                    route: 'authenticated.tas.applications.show',
                    model: instance.tasApplications[0]?.id
                };
            case 'TAS.ProgramType.1':
            case 'TAS.ProgramType.4':
            case 'TAS.ProgramType.4.2':
            case 'TAS.ProgramType.4.3':
                return {
                    route: 'authenticated.tas.programs.show',
                    model: instance?.id
                };
            default:
                return {
                    route: 'authenticated.tuition-assistance.programs.instance',
                    model: instance?.id
                };
        }
    }
    get instances() {
        return this.programInstances.map((instance)=>{
            const institutionLabel = instance.tasProgramTemplate.fields['SCHOOL_INSTITUTION_NAME'].label;
            const majorLabel = instance.tasProgramTemplate.fields?.['PROGRAM_MAJOR']?.label;
            const courseApplicationResults = this.courseApplicationData(instance);
            const institutionName = instance.institutionName || this.intl.t('tuition_assistance.not_provided');
            const typeClassification = instance.tasProgramTemplate.typeClassification;
            const buttonDetails = this.setButtonRoute(typeClassification, instance);
            const isTypeTwoOrThree = typeClassification === 'TAS.ProgramType.2' || typeClassification === 'TAS.ProgramType.3';
            const id = isTypeTwoOrThree ? instance.tasApplications[0]?.id : instance.id;
            return {
                title: instance.programName,
                id: id,
                isTrial: instance.isTrial,
                statusText: instance.latestStateChangeLabel,
                date: instance.latestStateChangeDate,
                buttonRoute: buttonDetails.route,
                buttonRouteModel: buttonDetails.model,
                buttonText: this.intl.t('tuition_assistance.view_details'),
                institution: {
                    [institutionLabel]: institutionName
                },
                beginDate: instance.estimatedProgramBegin,
                beginDateVisible: instance.tasProgramTemplate.fields?.['ESTIMATED_PROGRAM_BEGIN']?.visible,
                endDate: instance.estimatedProgramCompletion,
                endDateVisible: instance.tasProgramTemplate.fields?.['ESTIMATED_PROGRAM_COMPLETION']?.visible,
                result: courseApplicationResults,
                state: instance.state,
                isTrackingOnlyProgram: instance.tasProgramTemplate.isTrackingOnlyProgram,
                programMajor: {
                    [majorLabel]: instance.programMajor
                },
                isTypeTwoOrThree: isTypeTwoOrThree
            } as ProgramOverviewData;
        });
    }
    courseApplicationData(instance: TasProgramInstanceModel): CourseApplicationResults {
        const result = {
            pending: 0,
            in_progress: 0,
            completed: 0
        };
        const stateInProgress = [
            'TAS.ApplicationState.ATTEND',
            'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
            'TAS.ApplicationState.EVIDENCE_APPROVED',
            'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
            'TAS.ApplicationState.EVIDENCE_REJECTED'
        ];
        return instance.tasApplications.reduce((stateCounts, app)=>{
            if (app.state === 'TAS.ApplicationState.FULFILLED') {
                stateCounts.completed++;
            } else if (stateInProgress.includes(app.state)) {
                stateCounts.in_progress++;
            } else if (app.state !== 'TAS.ApplicationState.ABANDONED') {
                stateCounts.pending++;
            }
            return stateCounts;
        }, result);
    }
    @action
    showCourseApplicationsData(program: ProgramOverviewData): boolean {
        const isDraftOrPendingApproval = program.state === 'TAS.ProgramInstanceState.DRAFT' || program.state === 'TAS.ProgramInstanceState.PENDING_APPROVAL';
        return !isDraftOrPendingApproval && !program.isTypeTwoOrThree;
    }
    @action
    statusText(state: string) {
        if (this.intl.exists(`status_message.to.${state}`)) {
            return this.intl.t(`status_message.to.${state}`);
        }
        return this.intl.t('status_message.to.TAS.ApplicationState.UPDATED_ON');
    }
    static{
        template_cc9892a337a4460f9e020162fa730074(`
    <div ...attributes>
      <TuitionAssistanceDashboardProgramHeader />
      <div class="font-semibold mb-4">
        {{@sectionHeader}}
      </div>
      <div class="flex flex-wrap gap-4">
        {{#each this.instances as |program|}}
          <TuitionAssistanceProgramCard
            @buttonText={{program.buttonText}}
            @buttonRoute={{program.buttonRoute}}
            @buttonRouteModel={{program.buttonRouteModel}}
            @title={{program.title}}
            @titleClass="font-semibold"
            @showDashboardProgramMessagesIcon={{true}}
            @useTextLinkTo={{true}}
            @id={{program.id}}
            class="mt-4 mb-2"
          >
            {{#if program.isTrial}}
              <div class="flex justify-center items-center mb-2">
                <UiPill @label={{t "common.trial"}} @bgColorClass="bg-orange-700" />
              </div>
            {{/if}}
            <div class="mt-2">
              <DefinitionList class="!p-0" as |options|>
                <div class={{options.wrappingClass}}>
                  <options.dt>{{t "common.status"}}</options.dt>
                  <options.dd class="text-violet-800">
                    {{this.statusText program.statusText}}

                  </options.dd>
                </div>
                {{#each-in program.institution as |label value|}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{label}}</options.dt>
                    <options.dd class="font-medium">{{value}}</options.dd>
                  </div>
                {{/each-in}}
                {{#each-in program.programMajor as |label value|}}
                  {{#if (and label value)}}
                    <div class={{options.wrappingClass}}>
                      <options.dt>{{label}}</options.dt>
                      <options.dd class="font-medium">{{value}}</options.dd>
                    </div>
                  {{/if}}
                {{/each-in}}
                {{#if program.beginDateVisible}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{t "tuition_assistance.dashboard.program_start_date"}}</options.dt>
                    {{#if program.beginDate}}
                      <options.dd class="font-medium">{{safeFormatDate
                          program.beginDate
                        }}</options.dd>
                    {{else}}
                      <options.dd class="font-medium">{{t
                          "tuition_assistance.not_provided"
                        }}</options.dd>
                    {{/if}}
                  </div>
                {{/if}}
                {{#if program.endDateVisible}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{t "tuition_assistance.dashboard.program_end_date"}}</options.dt>
                    {{#if program.endDate}}
                      <options.dd class="font-medium">{{safeFormatDate
                          program.endDate
                        }}</options.dd>
                    {{else}}
                      <options.dd class="font-medium">{{t
                          "tuition_assistance.not_provided"
                        }}</options.dd>
                    {{/if}}
                  </div>
                {{/if}}
              </DefinitionList>
            </div>
            {{#if
              (and (this.showCourseApplicationsData program) (not program.isTrackingOnlyProgram))
            }}
              <div class="my-4">
                <p class="font-semibold">
                  {{t "tuition_assistance.dashboard.course_applications"}}
                </p>
                <DefinitionList class="!p-0" as |options|>
                  {{#each-in program.result as |key value|}}
                    <div class={{options.wrappingClass}}>
                      <options.dt>
                        {{t (concat "tuition_assistance.dashboard." key)}}
                      </options.dt>
                      <options.dd>
                        <div class="text-center border border-tio-gray-200 rounded-full w-9">
                          {{value}}
                        </div>
                      </options.dd>
                    </div>
                  {{/each-in}}
                </DefinitionList>
              </div>
            {{/if}}
          </TuitionAssistanceProgramCard>
        {{else}}
          <TuitionAssistanceProgramCard class="mt-4 mb-2">
            <p class="text-center my-2 font-semibold">
              {{t "tuition_assistance.dashboard.no_active_programs"}}
            </p>
          </TuitionAssistanceProgramCard>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
