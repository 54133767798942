import { template as template_2ca7c2e9a84b473da19a69150ba54e0f } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, eq, not, or } from 'tio-ui/utilities';
import { concat } from '@ember/helper';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { fn } from '@ember/helper';
import { Input, Textarea, Select } from 'tio-ui/components/forms';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { getSingleValueForTasField, setSingleValueForTasField, getAllValuesForTasField, type FieldName } from 'tio-common/utils/tuition-assistance/fields';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import Drawer from 'tio-ui/components/drawer';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import inputmask from 'tio-common/modifiers/inputmask';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type { TOC } from '@ember/component/template-only';
import type Store from '@ember-data/store';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import { Button } from 'tio-ui/components/buttons';
import type Owner from '@ember/owner';
import type { TASProgramTemplateModelFieldsSignature } from 'tio-common/models/tas-program-template';
const number = Number;
interface AddEducationDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
        application: TasApplication;
        course: TasCourse | null;
        header?: string;
    };
    Element: HTMLDivElement;
}
export interface BuiltInput {
    id?: string;
    name: keyof TasCourse['fields'];
    label: string;
    type: string;
    inputType: string;
    required: boolean;
    values: (string | number)[];
    errors: string[];
    options?: {
        value: string;
        label: string;
    }[];
    selectionMode?: 'single' | 'multiple';
    custom?: boolean;
    visible: boolean;
    ordinal: number;
    province: string;
    rules: {
        type: 'string' | 'number';
        typeError?: 'string';
    };
    value_as_field_option?: boolean;
}
export interface CustomInput extends CustomFieldSignature {
    custom: boolean;
    name?: string;
}
export interface CourseFieldsHash {
    [key: string]: {
        values: (string | number)[];
    };
}
interface RequiredAsteriskSignature {
    Args: {
        inputField: BuiltInput;
        application: TasApplication;
    };
    Element: HTMLSpanElement;
}
const RequiredAsterisk: TOC<RequiredAsteriskSignature> = template_2ca7c2e9a84b473da19a69150ba54e0f(`
  {{#if (eq @application.typeClassification "TAS.ProgramType.2")}}
    {{#if @inputField.required}}
      <span class="text-error-400">*</span>
    {{/if}}
  {{else if (and @inputField.required (not (eq @inputField.name "COURSE_GRADE")))}}
    <span class="text-error-400">*</span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class AddEducationDrawerComponent extends Component<AddEducationDrawerSignature> {
    @service
    store: typeof Store;
    inputs: BuiltInput[] = [];
    @tracked
    customInputs: CustomInput[] = [];
    @tracked
    selectedKeys: string[] = [];
    @tracked
    isLoading: boolean = false;
    @tracked
    trackedErrors: Record<string, string[]> = {};
    programTemplate: TasProgramTemplate;
    courseFields: TasCourse['fields'] = {} as TasCourse['fields'];
    customFields: TasCourse['customFields'] = [];
    createInputsForNewCourse(application: TasApplication): BuiltInput[] {
        const builtInputs = [];
        const programTemplate = application.tasProgramInstance.tasProgramTemplate;
        const courseFieldsHash = structuredClone(programTemplate.courseFieldsHash);
        for(const field in courseFieldsHash){
            // Merge the field data with input data
            const fieldInput = structuredClone(inputsForFields[field as keyof typeof inputsForFields]);
            const programField = structuredClone(programTemplate.fields[field as keyof TASProgramTemplateModelFieldsSignature]) as BuiltInput;
            if (field === 'COURSE_GRADE' && getAllValuesForTasField('EXCLUSIVE_ACADEMIC_PERFORMANCE_OPTIONS', programTemplate.fields).length) {
                const exclusiveOptions = getAllValuesForTasField('EXCLUSIVE_ACADEMIC_PERFORMANCE_OPTIONS', programTemplate.fields);
                programField.options = [];
                for (const option of exclusiveOptions){
                    programField.options.push({
                        value: option as string,
                        label: option as string
                    });
                }
            }
            const mergedInput = Object.assign(fieldInput, programField);
            builtInputs.push(mergedInput);
        }
        return builtInputs.sort((a, b)=>a.ordinal - b.ordinal) as BuiltInput[];
    }
    createInputsToEditCourse(application: TasApplication, course: TasCourse): BuiltInput[] {
        const builtInputs = [];
        const programTemplate = application.tasProgramInstance.tasProgramTemplate;
        // Deep clone courseFieldsHash to avoid any unintended mutations
        const courseFieldsHash = structuredClone(programTemplate.courseFieldsHash);
        for(const field in courseFieldsHash){
            // Clone template input and template field to prevent mutations
            const templateInput = structuredClone(inputsForFields[field as keyof typeof inputsForFields] || {});
            const templateField = structuredClone(programTemplate.fields[field as keyof typeof programTemplate.fields] || {});
            // Get the corresponding course field if it exists
            const courseField = structuredClone(course.fields[field as keyof typeof course.fields] || {});
            // Merge the course field values into the input, if the course field exists
            const mergedValues = courseField.values ? {
                values: courseField.values
            } : {};
            // Merge all inputs, ensuring immutability
            const mergedInput = Object.assign({}, templateInput, templateField, mergedValues);
            builtInputs.push(mergedInput);
        }
        return builtInputs.sort((a, b)=>a.ordinal - b.ordinal) as BuiltInput[];
    }
    createCustomInputs(customFields: CustomFieldSignature[]): CustomInput[] {
        return customFields.map((customField)=>({
                ...customField,
                custom: true
            }));
    }
    createErrorsObject(courseFieldsHash: CourseFieldsHash[], customFields: CustomFieldSignature[]): Record<string, string[]> {
        const errors: Record<string, string[]> = {};
        for(const field in courseFieldsHash){
            errors[field] = [];
        }
        for (const field of customFields){
            errors[field.label] = [];
        }
        return errors;
    }
    constructor(owner: Owner, args: AddEducationDrawerSignature['Args']){
        super(owner, args);
        const { application, course } = args;
        this.inputs = course!.isNew ? new TrackedArray(this.createInputsForNewCourse(application)) : new TrackedArray(this.createInputsToEditCourse(application, course!));
        this.courseFields = new TrackedObject(course!.fields);
        this.customFields = new TrackedArray(course!.customFields);
        this.customInputs = new TrackedArray(this.createCustomInputs(course!.customFields));
        this.programTemplate = application.tasProgramInstance.tasProgramTemplate;
        this.trackedErrors = new TrackedObject(this.createErrorsObject(this.programTemplate.courseFieldsHash, course!.customFields));
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    getErrorForInput(input: BuiltInput | CustomInput, trackedErrors: Record<string, string[]>): string[] {
        if (input.custom) {
            return trackedErrors[input.label] || [];
        } else {
            return trackedErrors[input.name!] || [];
        }
    }
    // This function is required for type three applications to by pass grade requirement here
    checkForRequired(input: BuiltInput) {
        if (input.name === 'COURSE_GRADE') {
            return false;
        }
        if (input.required) {
            return true;
        }
        return false;
    }
    @action
    checkForErrors(): void {
        for (const input of this.inputs){
            // Check if the input is required
            if (this.checkForRequired(input)) {
                if (input.values.length === 0) {
                    this.trackedErrors[input.name] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input.name];
                }
            }
            // Check for correct type (if there are values)
            if (input.values.length > 0) {
                const value = input.values[0];
                // If the expected type is "number"
                if (input.type === 'number') {
                    if (isNaN(Number(value))) {
                        this.trackedErrors[input.name] = [
                            'Value must be a number'
                        ];
                    } else {
                        delete this.trackedErrors[input.name];
                    }
                }
                // If the expected type is "string"
                if (input.type === 'string') {
                    if (typeof value !== 'string') {
                        this.trackedErrors[input.name] = [
                            'Value must be a string'
                        ];
                    } else {
                        delete this.trackedErrors[input.name];
                    }
                }
            }
        }
    }
    @action
    checkForCustomInputErrors() {
        for (const input of this.customInputs){
            // check if required
            if (input.required) {
                if (input.values.length === 0) {
                    this.trackedErrors[input.label] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input.label];
                }
            }
        }
    }
    @action
    noErrors(): boolean {
        for(const key in this.trackedErrors){
            if (Object.prototype.hasOwnProperty.call(this.trackedErrors, key)) {
                if (this.trackedErrors[key]?.length !== 0) {
                    return false;
                }
            }
        }
        return true;
    }
    @action
    async addEducation() {
        this.checkForErrors();
        this.checkForCustomInputErrors();
        if (this.noErrors()) {
            this.isLoading = true;
            try {
                this.setApprovedCourseTotalBeforeSave();
                this.args.course!.fields = this.courseFields;
                this.args.course!.customFields = this.customFields;
                await this.args.course!.save();
                this.inputs = [];
                this.customInputs = [];
                this.courseFields = {} as TasCourse['fields'];
                this.customFields = [];
                this.args.onClose();
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        }
    }
    @action
    getValue<T extends string | number | boolean | undefined>(values: (string | number | boolean)[]): T | undefined {
        const firstValue = values[0];
        if (firstValue === '' || firstValue === undefined) {
            return undefined as T;
        }
        return firstValue as T;
    }
    boolify(value: string): boolean {
        return !!value;
    }
    get courseExpenses() {
        const expenseInputs: FieldName[] = [
            'COURSE_TUITION',
            'COURSE_BOOKS',
            'COURSE_LABS',
            'COURSE_SOFTWARE',
            'COURSE_FEES'
        ];
        const reduced = expenseInputs.reduce((acc, input)=>{
            const value = getSingleValueForTasField(input, this.courseFields) as number;
            return acc + (isNaN(value) ? 0 : value);
        }, 0);
        return reduced;
    }
    setApprovedCourseTotalBeforeSave() {
        setSingleValueForTasField('APPROVED_COURSE_TOTAL', this.courseTotal, this.courseFields);
    }
    get courseTotal() {
        return this.programTemplate.calculateTotalByCredit ? this.reimbursementTotalByCredit : this.courseExpenses;
    }
    get reimbursementTotalByCredit() {
        return ((this.programTemplate.ratePerCredit ?? 0) * ((getSingleValueForTasField('COURSE_CREDIT', this.courseFields) as number) ?? 0));
    }
    @action
    setValue(input: BuiltInput, value: string | number | boolean) {
        if (input.type === 'currency') {
            value = currencyStringToNumber(value as string);
        }
        if (input.type === 'number') {
            // course credits can be halves ex: 2.5
            value = parseFloat(value as string);
            if (isNaN(value)) {
                this.trackedErrors[input.name] = [
                    'This field must be a number'
                ];
            } else {
                delete this.trackedErrors[input.name];
            }
        }
        // @ts-expect-error: boolean not assignable to string | number
        input.values = [
            value
        ];
        if (input.custom) {
            const index = input.ordinal - 1;
            this.customFields[index] = {
                ...this.customFields[index],
                values: [
                    value
                ] as string[] | boolean[]
            } as CustomFieldSignature;
        } else {
            setSingleValueForTasField(input.name, value, this.courseFields);
        }
    }
    static{
        template_2ca7c2e9a84b473da19a69150ba54e0f(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}} @size="xl">
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button type="button" data-test-close-education-drawer {{on "click" @onClose}}>
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-gray-600 font-semibold">
          {{@header}}</h2>
        <p class="text-gray-400 text-md">{{t
            "tas.add_education_drawer.fill_out_education_below"
          }}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">
            {{or @header (t "tas.add_education_drawer.education_information")}}
          </h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "tas.add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#each this.inputs as |input|}}
        {{#if input.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label for={{input.name}} class="font-semibold text-gray-600">
              {{input.label}}
              <RequiredAsterisk @inputField={{input}} @application={{@application}} />
            </label>
            {{! regular input with type currency }}
            {{#if (and (eq input.inputType "input") (eq input.type "currency"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value="{{divide (or (number (this.getValue input.values)) 0) 100}}"
                @onChange={{fn this.setValue input}}
                {{inputmask
                  alias="currency"
                  prefix="\$"
                  unmaskAsNumber=true
                  digits="2"
                  autoUnmask=true
                }}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-input-currency
              />
            {{/if}}
            {{! regular input with type string }}
            {{#if (and (eq input.inputType "input") (eq input.type "string"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{! regular input with type number }}
            {{#if (and (eq input.inputType "input") (eq input.type "number"))}}
              <Input
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{#if (eq input.inputType "textarea")}}
              <Textarea
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              />
            {{/if}}
            {{#if (eq input.inputType "radio")}}
              <FormRadioGroup
                data-test={{input.name}}
                @value={{this.getValue input.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue input}}
                {{!-- @errors={{errorsForField "employmentStatus" schemaErrors=this.formValidationErrors}} --}}
                @containerClass="py-4"
                as |Radio|
              >
                {{#each input.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
            {{#if (eq input.inputType "select")}}
              <Select
                data-test={{input.name}}
                @classes={{this.inputClasses}}
                @items={{input.options}}
                @selectionMode={{input.selectionMode}}
                @onAction={{fn this.setValue input}}
                {{! @glint-expect-error }}
                @selectedKeys={{input.values}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
              >
                <:item as |o|>
                  <o.Item @key={{o.item.value}} @intent="default" @appearance="faded">
                    {{o.item.value}}
                  </o.Item>
                </:item>
              </Select>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      {{#each this.customInputs as |customInput index|}}
        {{#if customInput.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label class="font-semibold text-gray-600" for={{customInput.name}}>
              {{customInput.label}}
              {{#if customInput.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            {{#if (eq customInput.input "textarea")}}
              <Textarea
                @classes={{this.inputClasses}}
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                data-test={{concat "custom-input-textarea-" index}}
              />
            {{/if}}
            {{#if (eq customInput.input "radio")}}
              <FormRadioGroup
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                @containerClass="py-4"
                data-test={{concat "custom-input-radio-" index}}
                as |Radio|
              >
                {{#each customInput.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      <div class="flex justify-start items-center gap-4 py-4">
        <Button @appearance="minimal" data-test-cancel-education-drawer {{on "click" @onClose}}>
          {{t "common.cancel"}}
        </Button>
        <Button
          type="submit"
          @intent="primary"
          @isRunning={{this.isLoading}}
          data-test-save-education-drawer
          {{on "click" this.addEducation}}
        >
          {{if @course.isNew (t "common.add") (t "common.save")}}
        </Button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
