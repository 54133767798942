import { template as template_3e02790029be45328bce29c9bbb724fc } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
export default RouteTemplate(template_3e02790029be45328bce29c9bbb724fc(`
    <div class="flex h-screen w-screen items-center justify-center">
      <TioLoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
