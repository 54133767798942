import { template as template_7ad0d21c50bc42b3b74ef07568c404c9 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import embedAwsQuicksight from 'tio-common/modifiers/embed-aws-quicksight';
interface S {
    Args: {
        model: {
            url: string;
        };
    };
}
export default RouteTemplate<S>(template_7ad0d21c50bc42b3b74ef07568c404c9(`
    <div class="w-full">
      <div {{embedAwsQuicksight @model.url}}></div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
