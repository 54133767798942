import { template as template_9a3b829fcfef4064921a59b9d16f623e } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import type AccountModel from 'tio-common/models/account';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from 'tio-employee/services/router';
import SlrAccountLinked from 'tio-employee/components/slr/account-linked';
import { t } from 'ember-intl';
import { Header, Section } from 'tio-ui/components/layout';
interface SyfLinkingConfirmationRouteSignature {
    Args: {
        model: AccountModel;
    };
}
class SyfLinkingConfirmationRouteComponent extends Component<SyfLinkingConfirmationRouteSignature> {
    @service
    router: RouterService;
    @action
    didConfirm() {
        this.router.transitionTo('authenticated.account-activity.index');
    }
    static{
        template_9a3b829fcfef4064921a59b9d16f623e(`
    <Header>{{t "account_linking.add_account"}}</Header>
    <Section>
      <:body>
        <SlrAccountLinked @account={{@model}} @onConfirmation={{this.didConfirm}} />
      </:body>
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfLinkingConfirmationRouteComponent);
