import { template as template_2a241ce278b8401093be5cf9cea1dff1 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { VStack, Header } from 'tio-ui/components/layout';
export default RouteTemplate(template_2a241ce278b8401093be5cf9cea1dff1(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
