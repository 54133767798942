import { template as template_48652c1bee1743e092734540d7965db7 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
const AppSidebarNavGroupsAdminEligibilityFilesComponent: TOC<Signature> = template_48652c1bee1743e092734540d7965db7(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.item
      @nestedRoute="admin.eligibility-files"
      @icon="upload_file"
      @label={{t "sidebar.eligibility_files"}}
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsAdminEligibilityFilesComponent;
