import { template as template_fee87fbe83204833a339b99b1bdd39cb } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_fee87fbe83204833a339b99b1bdd39cb(`
    {{pageTitle (t "recommendations_estimator.forgiveness_estimator")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
