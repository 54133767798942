import { template as template_970fe677b9a64b48aab0c7882a957475 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { t } from 'ember-intl';
import { Section, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_970fe677b9a64b48aab0c7882a957475(`
    <VStack>
      <Section>
        <:body>
          <AppPageHeroSection class="-mx-4 -mt-4">
            <:title>
              {{t "questions_you_have.repayment_plans.what_are_repayments_q"}}
            </:title>
            <:body>
              {{t "questions_you_have.repayment_plans.what_are_repayments_a"}}
            </:body>
          </AppPageHeroSection>

          <div class="grid cols-1 sm:grid-cols-3 gap-4">
            <div class="p-10">
              <h1 class="font-semibold text-xl">
                {{t "questions_you_have.repayment_plans.most_people_standard_title"}}
              </h1>
              <p>
                {{t "questions_you_have.repayment_plans.most_people_standard_description"}}
              </p>
            </div>
            <div class="p-10">
              <h1 class="font-semibold text-xl">
                {{t "questions_you_have.repayment_plans.payment_too_high_title"}}
              </h1>
              <p>
                {{t "questions_you_have.repayment_plans.payment_too_high_description"}}
              </p>
            </div>
            <div class="p-10">
              <h1 class="font-semibold text-xl">
                {{t "questions_you_have.repayment_plans.eligibility_should_be_considered_title"}}
              </h1>
              <p>
                {{t
                  "questions_you_have.repayment_plans.eligibility_should_be_considered_description"
                  htmlSafe=true
                }}
              </p>
            </div>
          </div>

          <div class="pt-10 p-4">
            <h1 class="text-2xl text-midnight font-bold">
              {{t "questions_you_have.repayment_plans.repayment_plan_types"}}
            </h1>
          </div>
          <TioPageTabs as |tabs|>
            <tabs.tab
              @route="authenticated.questions-you-have.repayment-plans.index"
              @label={{t "questions_you_have.repayment_plans.standard.tab_title"}}
            />
            <tabs.tab
              @route="authenticated.questions-you-have.repayment-plans.graduated"
              @label={{t "questions_you_have.repayment_plans.graduated.tab_title"}}
            />
            <tabs.tab
              @route="authenticated.questions-you-have.repayment-plans.extended"
              @label={{t "questions_you_have.repayment_plans.extended.tab_title"}}
            />
            <tabs.tab
              @route="authenticated.questions-you-have.repayment-plans.income-driven"
              @label={{t "questions_you_have.repayment_plans.income_driven.tab_title"}}
            />
          </TioPageTabs>

          {{outlet}}
        </:body>
      </Section>
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
