import { template as template_c645c10bc3f947d69b6556e18fcc0a9b } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
const AppSidebarNavGroupsAdminPslfComponent: TOC<Signature> = template_c645c10bc3f947d69b6556e18fcc0a9b(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.item @nestedRoute="admin.pslf.forms" @icon="support" @label={{t "sidebar.admin_pslf"}} />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsAdminPslfComponent;
