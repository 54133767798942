import { template as template_51fedb1381164828ba66d12e60d481bd } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import RouteTemplate from 'ember-route-template';
import type Store from '@ember-data/store';
import TuitionAssistanceFormsCoursesCourseDetails from 'tio-common/components/tuition-assistance/forms/courses/course-details';
import type TasCourse from 'tio-common/models/tas-course';
interface TAProgramInstanceCourseApplicationsShowPreApprovalEditCourseRouteComponentSignature {
    Args: {
        model: TasCourse;
    };
}
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class TAProgramInstanceCourseApplicationsShowPreApprovalEditCourseRouteComponent extends Component<TAProgramInstanceCourseApplicationsShowPreApprovalEditCourseRouteComponentSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @tracked
    hasSubmitted = false;
    @action
    cancel() {
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.index');
    }
    @action
    async saveForLater(fieldsCopy: TasCourse['fields'], customFieldsCopy: TasCourse['customFields']) {
        await this.saveFields.perform(fieldsCopy, customFieldsCopy);
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.course-applications.show');
    }
    @action
    async saveAndNext(fieldsCopy: TasCourse['fields'], customFieldsCopy: TasCourse['customFields'], isFormValid: boolean) {
        if (!isFormValid) {
            return;
        }
        await this.saveFields.perform(fieldsCopy, customFieldsCopy);
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.review');
    }
    saveFields = dropTask(async (fieldsCopy, customFieldsCopy)=>{
        this.args.model.fields = fieldsCopy;
        this.args.model.customFields = customFieldsCopy;
        try {
            this.hasSubmitted = true;
            await this.args.model.save();
        } catch (e) {
            console.error(e);
        }
    });
    static{
        template_51fedb1381164828ba66d12e60d481bd(`
    <h3 class="font-semibold mt-2 text-midnight">
      {{t "tuition_assistance.program_details.courses.course_information"}}
    </h3>
    <h4 class="font-semibold">
      {{t "tuition_assistance.program_details.courses.please_add_courses"}}
    </h4>

    <TuitionAssistanceFormsCoursesCourseDetails
      @course={{@model}}
      @saveAndNext={{this.saveAndNext}}
      @saveForLater={{this.saveForLater}}
      @cancel={{this.cancel}}
      @showActionButtons={{true}}
      @saveTaskIsRunning={{this.saveFields.isRunning}}
      @hasSubmitted={{this.hasSubmitted}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TAProgramInstanceCourseApplicationsShowPreApprovalEditCourseRouteComponent);
