import { template as template_60b24271716443bb977c8524145534b4 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import type PersonModel from 'tio-common/models/person';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AnswerProfileService from 'tio-employee/services/answer-profile';
import type RecomendationModel from 'tio-common/models/recommendation';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import AddAccountTodo from './add-account-todo';
import ConsolidationTodo from './consolidation-todo';
import RecertifyTodo from './recertify-todo';
import RepaymentPlanTodo from './repayment-plan-todo';
import { Section, VStack } from 'tio-ui/components/layout';
export interface DashRecommendationsSignature {
    Args: {
        loanSummary: LoanSummary;
        person: PersonModel;
        recommendation: RecomendationModel;
    };
    Element: HTMLDivElement;
}
export default class DashRecommendationsComponent extends Component<DashRecommendationsSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service('answer-profile')
    answerProfileService: AnswerProfileService;
    answerProfileState = trackedFunction(this, async ()=>{
        const profile = await this.answerProfileService.findOrCreateAnswerProfileForCurrentUser();
        return profile;
    });
    get answerProfile() {
        return this.answerProfileState.value;
    }
    get loanSummary() {
        return this.args.loanSummary;
    }
    get loanSummariesByType() {
        return this.loanSummary?.summaryByLoanType || [];
    }
    get loans() {
        return this.args.person.allActiveAccountLoans || [];
    }
    get shouldConsolidateLoans() {
        return !!this.loanSummariesByType.find((summary)=>{
            return summary.loanType !== 'direct';
        });
    }
    get shouldChangeRepaymentPlan() {
        const validRepaymentPlansForPslf = [
            'icr',
            'ibr',
            'paye',
            'repaye',
            'fivePercentUndergrad'
        ];
        const allRepaymentPlans = [
            ...new Set(this.loanSummariesByType.flatMap((summary)=>summary.repaymentPlans))
        ];
        return allRepaymentPlans.every((plan)=>!validRepaymentPlansForPslf.includes(plan));
    }
    get shouldLinkLoans() {
        return this.args.person.needsToLinkLoans;
    }
    get hasNoRecommendations() {
        return !(this.shouldConsolidateLoans || this.shouldChangeRepaymentPlan || this.shouldLinkLoans || this.needsToRecertify);
    }
    get hasTakenEstimator() {
        return !!this.args.recommendation;
    }
    get needsToRecertify() {
        return this.hasTakenEstimator;
    }
    static{
        template_60b24271716443bb977c8524145534b4(`
    {{#unless this.hasNoRecommendations}}
      <Section>
        <:header>{{t "recommendations.default"}}</:header>
        <:body>
          <VStack @collapsed={{true}}>
            {{#if this.shouldLinkLoans}}
              <AddAccountTodo />
            {{/if}}
            {{#if this.shouldConsolidateLoans}}
              <Section>
                <ConsolidationTodo @answerProfile={{this.answerProfile}} />
              </Section>
            {{/if}}
            {{#if this.shouldChangeRepaymentPlan}}
              <Section>
                <RepaymentPlanTodo @answerProfile={{this.answerProfile}} />
              </Section>
            {{/if}}
            {{#if this.needsToRecertify}}
              <RecertifyTodo @answerProfile={{this.answerProfile}} />
            {{/if}}
          </VStack>
        </:body>
      </Section>
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
