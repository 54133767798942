import { template as template_d2d825a353b14ddf95b76fc18a5eadbb } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type FeaturesService from 'tio-common/services/features';
// uncomment when feature flag is removed
// import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import t from 'ember-intl/helpers/t';
import type { NavGroup } from 'tio-common/components/ui/sidebar/nav';
interface AppSidebarNavGroupsAdminTuitionAssistanceSignature {
    Args: {
        nav: {
            group: NavGroup;
        };
    };
}
// can revert to TOC when feature flad is removed
// const AppSidebarNavGroupsAdminTuitionAssistanceComponent: TOC<AppSidebarNavGroupsAdminTuitionAssistanceSignature> =
class AppSidebarNavGroupsAdminTuitionAssistanceComponent extends Component<AppSidebarNavGroupsAdminTuitionAssistanceSignature> {
    @service
    features: FeaturesService;
    get taPartnerPaymentsReviewPageEnabled() {
        return this.features.isEnabled('taPartnerPaymentsReviewPage');
    }
    static{
        template_d2d825a353b14ddf95b76fc18a5eadbb(`
    {{#let @nav as |nav|}}
      <nav.group
        @icon="school"
        @label={{t "sidebar.tuition_assistance"}}
        @nestedRoute="admin.tuition-assistance"
        as |group|
      >
        <group.item @nestedRoute="dashboard" @label="Dashboard" />
        {{#if this.taPartnerPaymentsReviewPageEnabled}}
          <group.item @nestedRoute="payments" @label="Payments" />
        {{/if}}
        <group.item
          @nestedRoute="applications.index"
          @label={{t "tuition_assistance.applications.default"}}
        />
        {{! TODO: Eventually this should be a single link that goes to reporting index page with links to each page }}
        <group.item
          @route="authenticated.admin.reporting.index"
          @label={{t "reports.all_reports_link"}}
        />
        {{! <group.item @route="authenticated.admin.reporting" @label="Reports" /> }}
      </nav.group>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default AppSidebarNavGroupsAdminTuitionAssistanceComponent;
