import { template as template_4510f280e9334d58abbcfee8520fedc1 } from "@ember/template-compiler";
import type StoreService from 'tio-common/services/store';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import Table from 'tio-common/components/table/index';
import TioExpandableSection from 'tio-common/components/tio/expandable-section';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import divide from 'ember-math-helpers/helpers/div';
import UiPill from 'tio-common/components/ui/pill';
import type EmployeeModel from 'tio-common/models/employee';
import type IntlService from 'ember-intl/services/intl';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
const number = Number;
export interface TuitionAssistanceProfileTuitionAssistanceSignature {
    Args: {
        employee: EmployeeModel;
    };
    Element: HTMLElement;
}
export default class TuitionAssistanceProfileTuitionAssistanceComponent extends Component<TuitionAssistanceProfileTuitionAssistanceSignature> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    get employee() {
        return this.args.employee;
    }
    routeForModelName(modelName: string) {
        if (modelName === 'tas-program-instance') {
            return 'authenticated.admin.tuition-assistance.program-instances.show.readonly';
        }
        if (modelName === 'tas-application') {
            return 'authenticated.admin.tuition-assistance.applications.show.readonly';
        }
        return '';
    }
    labelForModelName(modelName: string) {
        if (modelName === 'tas-program-instance') {
            return 'Program Application';
        }
        if (modelName === 'tas-application') {
            return 'Courses Application';
        }
        return '';
    }
    tasProgramInstancesData = trackedFunction(this, async ()=>{
        if (!this.employee) {
            return [];
        }
        const instances = await this.store.query('tas-program-instance', {
            filter: {
                employee: this.employee.id
            },
            include: 'tas-program-template,tas-applications.tas-courses,tas-applications.tas-assistances'
        });
        return instances;
    });
    get isLoading() {
        return this.tasProgramInstancesData.isLoading;
    }
    get tasProgramInstances() {
        return this.tasProgramInstancesData.value || [];
    }
    @action
    tasApplications(instance?: TasProgramInstanceModel) {
        return instance?.tasApplications.slice() || [];
    }
    @action
    tasCourseNames(application?: TasApplicationModel) {
        const courses = application?.activeCourses.slice() || [];
        const courseNames = courses.map((course)=>{
            return course.displayName;
        });
        return courseNames.join(', ');
    }
    @action
    statusText(state: string): string {
        if (this.intl.exists(`status_message.to.${state}`)) {
            return this.intl.t(`status_message.to.${state}`);
        }
        return this.intl.t('status_message.to.TAS.ProgramInstanceState.UPDATED_ON');
    }
    get records() {
        return [
            ...this.tasProgramInstances
        ];
    }
    static{
        template_4510f280e9334d58abbcfee8520fedc1(`
    <TioExpandableSection ...attributes @isOpen={{false}}>
      <:header>
        <h3 class="text-lg font-semibold text-left">
          {{t "tuition_assistance.default"}}
        </h3>
      </:header>
      <:content>
        <Table @isLoading={{this.isLoading}}>
          <:thead>
            <tr class="font-semibold text-left bg-ocean-50">
              {{#let "px-2 py-1 border border-gray-300" as |cellClass|}}
                <th class={{cellClass}}>{{t "id"}}</th>
                <th class={{cellClass}}>{{t "tuition_assistance.application_name"}}</th>
                <th class={{cellClass}}>
                  {{t "tuition_assistance.program_details.application_history.state"}}
                </th>
                <th class={{cellClass}}>
                  {{t "tuition_assistance.program_details.status.paid_date"}}
                </th>
                <th class={{cellClass}}>
                  {{t "tuition_assistance.program_details.status.paid_total"}}
                </th>
                <th class={{cellClass}}>
                  {{t "tuition_assistance.program_details.status.approved_total"}}
                </th>
              {{/let}}
            </tr>
          </:thead>
          <:tbody>
            {{#let "px-2 py-1 border border-gray-300 align-top" as |cellClass|}}

              {{#each this.records as |record|}}
                <tr>
                  <td class="{{cellClass}} text-ocean-600 underline">
                    <LinkTo @route={{this.routeForModelName record.modelName}} @model={{record.id}}>
                      {{record.id}}
                    </LinkTo>
                  </td>
                  <td class={{cellClass}}>
                    {{record.adminDisplayName}}
                  </td>
                  <td class={{cellClass}}>
                    {{this.statusText record.state}}
                  </td>
                  <td class={{cellClass}}>
                    -
                  </td>
                  <td class={{cellClass}}>
                    -
                  </td>
                  <td class={{cellClass}}>
                    -
                  </td>
                </tr>
                {{#each (this.tasApplications record) as |application|}}
                  <tr class="bg-tio-gray-100">
                    <td class="{{cellClass}} pl-9 pr-3 text-ocean-600">
                      <LinkTo
                        @route={{this.routeForModelName application.modelName}}
                        @model={{application.id}}
                        class="underline"
                      >
                        {{application.id}}
                      </LinkTo>
                      {{#if application.isRefundRequested}}
                        <UiPill
                          @label={{t "tuition_assistance.application_details.in_repayment"}}
                          @bgColorClass="bg-orange-700"
                          class="ml-2 text-nowrap"
                        />
                      {{/if}}
                    </td>
                    <td class={{cellClass}}>
                      {{application.displayName}}
                      {{#if (this.tasCourseNames application)}}
                        ({{this.tasCourseNames application}})
                      {{/if}}
                    </td>
                    <td class={{cellClass}}>
                      {{this.statusText application.state}}
                    </td>
                    {{#if application.isPaid}}
                      <td class={{cellClass}}>
                        {{! @glint-expect-error}}
                        {{safeFormatDate application.paidDate}}
                      </td>
                    {{else}}
                      <td class={{cellClass}}>
                        -
                      </td>
                    {{/if}}
                    {{#if application.isPaid}}
                      <td class={{cellClass}}>
                        {{safeFormatNumber
                          (divide application.paidTotal 100)
                          style="currency"
                          currency="USD"
                        }}
                      </td>
                    {{else}}
                      <td class={{cellClass}}>
                        -
                      </td>
                    {{/if}}
                    {{#if application.isApproved}}
                      <td class={{cellClass}}>
                        {{safeFormatNumber
                          (divide (number application.approvedTotal) 100)
                          style="currency"
                          currency="USD"
                        }}
                      </td>
                    {{else}}
                      <td class={{cellClass}}>
                        -
                      </td>
                    {{/if}}
                  </tr>
                {{/each}}
              {{else}}
                <tr>
                  <td colspan="5" class={{cellClass}}>
                    {{t "common.no_records_found"}}
                  </td>
                </tr>
              {{/each}}
            {{/let}}
          </:tbody>
        </Table>
      </:content>
    </TioExpandableSection>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
