import { template as template_8c632f21f45c482eaa1a2cd9a7f4a7ba } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Tile from '../tile';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsStrategyFinderComponent: TOC = template_8c632f21f45c482eaa1a2cd9a7f4a7ba(`
  <LinkTo @route="authenticated.repaying-student-debt.repayment-strategy-finder">
    <Tile @headerText={{t "common.strategy_finder.default"}}>
      <:description>
        {{t "dashboard_tiles.find_best_strategy"}}
      </:description>
      <:image>
        {{svgJar
          "marketing-strategy"
          width="90%"
          height="100%"
          role="img"
          desc=(t "svg.marketing_strategy")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsStrategyFinderComponent;
