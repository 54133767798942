import { template as template_ef17c963e4ea4fdca11549fd387f3d2c } from "@ember/template-compiler";
import { htmlSafe } from '@ember/template';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type EmployeeService from 'tio-employee/services/employee';
import formatDateString from 'tio-common/helpers/format-date-string';
import SvgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import TioButton from 'tio-common/components/tio/button';
import type { IntlService } from 'ember-intl';
class DashboardWidgetSecureYourFutureComponent extends Component {
    @service
    employee: EmployeeService;
    @service
    intl: IntlService;
    get continueEnrollment() {
        return this.employee?.current?.secureEnrollmentInProgress;
    }
    get hasEnrolled() {
        return this.employee?.current?.secureEnrollmentComplete;
    }
    get benefitStartDate() {
        return this.employee?.current?.secureBenefitStartDate;
    }
    get descriptionText() {
        const enrollmentStatus = this.hasEnrolled ? this.intl.t('syf.enrollment.complete') : this.intl.t('syf.enrollment.incomplete');
        const messageKey = this.continueEnrollment ? 'dashboard_tiles.secure_your_future_continue_enrollment' : 'dashboard_tiles.secure_your_future';
        return this.intl.t(messageKey, {
            enrolled: enrollmentStatus
        });
    }
    static{
        template_ef17c963e4ea4fdca11549fd387f3d2c(`
    {{#if this.continueEnrollment}}
      <LinkTo @route="authenticated.syf.enrollment">
        <Tile @headerText={{t "syf.default"}}>
          <:description>
            {{htmlSafe this.descriptionText}}
            <p class="mt-4">
              {{t "dashboard_tiles.benefit_available_on"}}
              <span class="font-semibold">{{formatDateString this.benefitStartDate}}</span>
            </p>
            <div class="flex justify-center mt-10">
              <TioButton
                @outlined={{false}}
                @linkTo="authenticated.syf.enrollment"
                class="max-w-fit"
              >
                {{t "dashboard_tiles.continue_enrollment"}}
              </TioButton>
            </div>
          </:description>
        </Tile>
      </LinkTo>
    {{else}}
      <LinkTo @route="authenticated.syf.enrollment">
        <Tile @headerText={{t "syf.default"}}>
          <:description>
            {{htmlSafe this.descriptionText}}
            <p class="mt-2">
              {{t "dashboard_tiles.benefit_available_on"}}
              <span class="font-semibold">{{formatDateString this.benefitStartDate}}</span>
            </p>
          </:description>
          <:image>
            {{SvgJar
              "secure-your-future"
              width="85%"
              height="95%"
              role="img"
              desc=(t "svg.secure_your_future")
            }}
          </:image>
        </Tile>
      </LinkTo>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default DashboardWidgetSecureYourFutureComponent;
