import { template as template_7869e7c956334e458cab2a3ec3254a01 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsFiveTwoNineToolsComponent: TOC = template_7869e7c956334e458cab2a3ec3254a01(`
  <LinkTo @route="authenticated.planning-for-college.529-tools">
    <Tile @headerText={{t "dashboard_tiles.529_plans"}}>
      <:description>
        {{t "dashboard_tiles.save_now"}}
      </:description>
      <:image>
        {{svgJar
          "529-piggy"
          width="90%"
          height="100%"
          role="img"
          desc=(t "svg.five_two_nine_piggy")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsFiveTwoNineToolsComponent;
