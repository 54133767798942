import { template as template_d7ae68ed4a624e4bb6c75991117c90e4 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type TasCourseModel from 'tio-common/models/tas-course';
import { t } from 'ember-intl';
import TuitionAssistanceFormsCoursesCourseAddGrade from 'tio-employee/components/tuition-assistance/forms/courses/course-add-grade';
interface S {
    Args: {
        model: TasCourseModel;
    };
}
export default RouteTemplate<S>(template_d7ae68ed4a624e4bb6c75991117c90e4(`
    <h3 class="font-semibold mt-2 text-midnight">
      {{t "tuition_assistance.program_details.courses.course_information"}}
    </h3>
    <h4 class="font-semibold">
      {{t "tuition_assistance.program_details.courses.please_add_grades_and_attachments"}}
    </h4>

    <TuitionAssistanceFormsCoursesCourseAddGrade @course={{@model}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
