import { template as template_e79aab7d672b4b70809273a3a41a3479 } from "@ember/template-compiler";
import type StoreService from 'tio-common/services/store';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import EligibilityFilesEligibilityTableRow from 'tio-common/components/eligibility-files/eligibility-table-row';
import Table from 'tio-common/components/table/index';
import TablePaginator from 'tio-common/components/table/paginator';
import TioEligibilityUploadModal from 'tio-common/components/tio/eligibility-upload-modal';
import type EligibilityFileJobLogModel from 'tio-common/models/eligibility-file-job-log';
import { statusFromDisplayName, statusOptions } from 'tio-common/utils/eligibility-upload/status-lookups';
import type IndexController from 'tio-employee/controllers/authenticated/admin/eligibility-files/index';
import type IntlService from 'ember-intl/services/intl';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import ArrowUpTray from 'ember-static-heroicons/components/outline-24/arrow-up-tray';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import { Input, Select } from 'tio-ui/components/forms';
import { array } from '@ember/helper';
export interface S {
    Args: {
        model: EligibilityFileJobLogModel[];
        controller: IndexController;
    };
}
class AuthenticatedAdminEligibilityFilesIndexRouteComponent extends Component<S> {
    @service
    partner: PartnerService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @tracked
    showUploadModal = false;
    get selectedStatus() {
        return this.intl.t('common.eligibility_file_upload.status.' + (this.args.controller.status || 'ALL'));
    }
    convertFromReadableName(status: string) {
        return statusFromDisplayName(status) || 'ALL';
    }
    @action
    setSelectedStatus([selectedStatus]: string[]) {
        if (selectedStatus === 'ALL' || !selectedStatus) {
            this.args.controller.status = '';
        } else {
            this.args.controller.status = this.convertFromReadableName(selectedStatus);
        }
    }
    @action
    setSelectedStartDate(startDate: string) {
        this.args.controller.startDate = startDate;
    }
    @action
    onSort(sort: string) {
        this.args.controller.sort = sort;
    }
    @action
    toggleUploadEligibilityFileModal() {
        this.showUploadModal = !this.showUploadModal;
    }
    @action
    closeModal() {
        this.showUploadModal = false;
    }
    @action
    previousPage() {
        this.args.controller.page = Math.max(1, this.args.controller.page - 1);
    }
    @action
    nextPage() {
        ++this.args.controller.page;
    }
    get totalPages() {
        const { model, controller } = this.args;
        // @ts-expect-error: ed needs types
        const meta = model?.meta;
        if (meta?.page.total) {
            return Math.ceil(meta.page.total / controller.limit);
        }
        return 0;
    }
    static{
        template_e79aab7d672b4b70809273a3a41a3479(`
    {{pageTitle (t "partner.eligibility_files.eligibility_files")}}

    <VStack>
      <Header>{{t "partner.eligibility_files.eligibility_files"}}</Header>
      <Section class="sticky top-0 z-10">
        <:body>
          <HStack class="sm:px-4 md:items-end">
            <Input
              @label={{t "partner.eligibility_files.filter_by_date"}}
              type="date"
              @value={{@controller.startDate}}
              @onInput={{this.setSelectedStartDate}}
              class="w-64"
            />
            <Select
              @label={{t "partner.eligibility_files.filter_by_status"}}
              @items={{statusOptions}}
              @selectedKeys={{array this.selectedStatus}}
              @onSelectionChange={{this.setSelectedStatus}}
              @isClearable={{true}}
              class="w-64"
            />
            <Button
              @appearance="outlined"
              @intent="primary"
              {{on "click" this.toggleUploadEligibilityFileModal}}
              class="w-64"
            >
              <ArrowUpTray class="w-4 mr-2" />
              {{t "partner.eligibility_files.upload_eligibility_file"}}
            </Button>
          </HStack>
        </:body>
      </Section>
      <Section>
        <:body>
          <Table @isLoading={{@controller.isLoading}}>
            <:thead as |options|>
              <options.tr>
                <options.th
                  @sortField="file-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.eligibility_files.file_name"}}
                </options.th>
                <options.th
                  @sortField="file-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.eligibility_files.file_name"}}
                </options.th>
                <options.th
                  @sortField="strategy"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.eligibility_files.strategy"}}
                </options.th>
                <options.th
                  @sortField="start-time"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.eligibility_files.started_on"}}
                </options.th>
                <options.th
                  @sortField="status"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.eligibility_files.status"}}
                </options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |item|}}
                {{! @glint-expect-error: this component expects so many arguments }}
                <EligibilityFilesEligibilityTableRow
                  @showRoute="authenticated.admin.eligibility-files.show"
                  @item={{item}}
                  @cellClass={{options.cellClass}}
                />
              {{/each}}
            </:tbody>
            <:tfoot>
              <tr>
                <td class="text-right" colspan="9">
                  <TablePaginator
                    @page={{@controller.page}}
                    @totalPages={{this.totalPages}}
                    @nextPage={{this.nextPage}}
                    @previousPage={{this.previousPage}}
                    @limit={{@controller.limit}}
                    {{! @glint-expect-error: ed needs types }}
                    @total={{@model.meta.page.total}}
                  />
                </td>
              </tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
    {{#if this.showUploadModal}}
      <TioEligibilityUploadModal
        {{! @glint-expect-error: need to unify partial commpany types from session }}
        @company={{this.partner.company}}
        @cancel={{this.closeModal}}
        @toggle={{this.toggleUploadEligibilityFileModal}}
        @showRoute="authenticated.admin.eligibility-files.show"
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AuthenticatedAdminEligibilityFilesIndexRouteComponent);
